import { Component, Input, OnInit } from '@angular/core';
import { CacheService } from '../../services';

@Component({
  standalone: false,
  selector: 'cached-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  @Input() cache: any;
  @Input() radius: string = '';
  @Input() src: any;
  @Input() alt: any;

  image: string = '';

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public caches: CacheService
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  ngOnInit() {
    this.caches.getImage(this.src, this.cache).then(img => {
      this.image = img;
    });
  }
}
