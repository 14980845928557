import { appDebug } from '../../../environments/environment';
import { Directory, Filesystem, Encoding } from '@capacitor/filesystem';
import { CapacitorHttp } from '@capacitor/core';
import { Injectable } from '@angular/core';
import { CapacitorService, FirebaseService } from '..';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})

export class CacheService {

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public capacitor: CapacitorService,
    public firebase: FirebaseService
  ) { }

  //----------------------------------------------------------------------------
  // Get Image
  //----------------------------------------------------------------------------

  async getImage(url: string, cache: any, map: boolean = false): Promise<any> {
    if (url) {

      // Image
      if (!cache) return url;
      const path = url.split('/').pop();

      // Directory
      let dir = Capacitor.isNativePlatform() ? Directory.Cache : Directory.Data;
      let folder = map ? 'map' : 'images';

      // Read
      return await Filesystem.readFile({
        directory: dir,
        path: folder + '/' + path

      }).then(async readFile => {
        const type = readFile.data ? (readFile.data as string).split('.').pop() : 'jpg';
        return 'data: ' + folder + '/' + type + '; base64,' + readFile.data;

      }).catch(async error => {
        this.storeImage(path, url, folder, dir);
        return url;
      });
    }
  }

  //----------------------------------------------------------------------------
  // Store Image
  //----------------------------------------------------------------------------

  async storeImage(path: any, url: any, folder: string, dir: any) {
    try {
      const image = await CapacitorHttp.request({
        responseType: 'blob',
        method: 'GET',
        url: url
      });
      if (image.status != 404) {
        await Filesystem.writeFile({
          directory: dir,
          path: folder + '/' + path,
          data: image.data,
          recursive: true
        });
      }
    } catch(error) {
      return null;
    }
    return null;
  }

  //----------------------------------------------------------------------------
  // Get Cache
  //----------------------------------------------------------------------------

  async getCache(path: string): Promise<any> {

    // Read
    return await Filesystem.readFile({
      directory: Directory.Cache,
      encoding: Encoding.UTF8,
      path: 'json/' + path + '.json'

    }).then(async readFile => {
      let data;

      // Debug
      if (appDebug) console.log('[CACHE:GET]', '/json/' + path);

      // Return
      data = JSON.parse((readFile.data as string));
      if (data?.view && data?.view[0]) data.view = data.view[0];
      return data;

    }).catch(async error => {
      return;
    });
  }

  //----------------------------------------------------------------------------
  // Store Cache
  //----------------------------------------------------------------------------

  async storeCache(path: any, data: any) {
    if (await this.capacitor.checkAuthorization() && data &&
      data?.view?.edit || path == 'goals' || path == 'journal' ||
      path == 'places' || path == 'friends' || path == 'account' || path == 'map'
    ) {

      // Debug
      if (appDebug) console.log('[CACHE:STORED]', 'json/' + path);

      // Write
      try {
        await Filesystem.writeFile({
          directory: Directory.Cache,
          encoding: Encoding.UTF8,
          path: 'json/' + path + '.json',
          data: JSON.stringify(data),
          recursive: true
        });
      } catch (e) { }
    }
  }

  //----------------------------------------------------------------------------
  // Remove Cache
  //----------------------------------------------------------------------------

  async removeCache(view: any) {

    // Native
    if (!await this.capacitor.isNative()) return;

    // Read
    return await Filesystem.readFile({
      directory: Directory.Cache,
      path: 'json/' + view + '.json'

      // Delete
    }).then(async readFile => {
      await Filesystem.deleteFile({
        directory: Directory.Cache,
        path: 'json/' + view + '.json'
      });
    });
  }

  //----------------------------------------------------------------------------
  // Clear Cache
  //----------------------------------------------------------------------------

  async clearCache() {

    // Native
    if (!await this.capacitor.isNative()) return;

    // Clear JSON
    const dirJson = await Filesystem.readdir({
      directory: Directory.Cache,
      path: 'json'
    });
    dirJson.files.map(async file => {
      if (appDebug) console.log('[CACHE:DELETE]', file);
      await Filesystem.deleteFile({
        directory: Directory.Cache,
        path: 'json/' + file.name
      });
    });

    // Clear Images
    const dirImages = await Filesystem.readdir({
      directory: Directory.Cache,
      path: 'images'
    });
    dirImages.files.map(async file => {
      if (appDebug) console.log('[CACHE:DELETE]', file.name);
      await Filesystem.deleteFile({
        directory: Directory.Cache,
        path: 'images/' + file.name
      });
    });
  }
}
