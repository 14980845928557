import { appDebug } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { Capacitor } from '@capacitor/core';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDieZJn7gm3lrQF6qxV1xJ_RmVMSpH_Aqk",
  authDomain: "web.ibucket.app",
  databaseURL: "https://ibucket-38a45.firebaseio.com",
  projectId: "ibucket-38a45",
  storageBucket: "ibucket-38a45.appspot.com",
  messagingSenderId: "611406298035",
  appId: "1:611406298035:web:4e8de56c801bc8c7",
  measurementId: "G-NP5T47KWBT"
};

@Injectable({
  providedIn: 'root'
})

export class FirebaseService {

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor() { }

  //----------------------------------------------------------------------------
  // Initialize Firebase
  //----------------------------------------------------------------------------

  async initFirebase() {
    if (!Capacitor.isNativePlatform()) {
      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
    }
  }

  //----------------------------------------------------------------------------
  // Set Screen
  //----------------------------------------------------------------------------

  async setScreen(name: string) {
    if (appDebug) console.log('[FIREBASE:SCREEN]', name);
    if (name) FirebaseAnalytics.setCurrentScreen({
      screenName: name
    });
  }

  //----------------------------------------------------------------------------
  // Set Event
  //----------------------------------------------------------------------------

  async setEvent(name: string) {
    if (appDebug) console.log('[FIREBASE:EVENT]', name);
    if (name) FirebaseAnalytics.logEvent({
      name: name
    });
  }
}