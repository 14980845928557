import { Component, OnInit } from '@angular/core';
import { IonicModule, ModalController, Platform } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SocialLogin } from '@capgo/capacitor-social-login';
import { ComponentsModule } from '../../components';
import { Router } from '@angular/router';
import {
  CapacitorService,
  FirebaseService,
  EventsService,
  IonicService,
  HttpService
} from '../../services';
import { socialAppleClient, socialAppleRedirect, socialGoogleiOS, socialGoogleServer, socialGoogleWeb } from '../../../environments/environment';

@Component({
  standalone: true,
  selector: 'page-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  imports: [
    IonicModule, CommonModule, FormsModule,
    ComponentsModule, TranslateModule
  ],
})
export class LoginPage implements OnInit {

  // Variables
  name!: string;
  email!: string;
  type!: string;
  spinner: boolean = false;
  token: string = this.ionic.getView('token');
  web: boolean = this.platform.is('mobileweb');

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public capacitor: CapacitorService,
    public translate: TranslateService,
    public modalLogin: ModalController,
    public firebase: FirebaseService,
    public events: EventsService,
    public ionic: IonicService,
    public platform: Platform,
    public http: HttpService,
    public router: Router
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  async ngOnInit() {
    this.firebase.setScreen('Login');
    this.token = !this.type && this.token ? this.token : '';
    this.initSocial();
  }

  //------------------------------------------------------------------------------
  // Initialize Social
  //------------------------------------------------------------------------------

  async initSocial() {
    if (await this.capacitor.isNative() && await this.capacitor.getPlatform() == 'ios') {
      await SocialLogin.initialize({
        apple: {
          clientId: socialAppleClient
        },
        google: {
          iOSClientId: socialGoogleiOS,
          iOSServerClientId: socialGoogleServer,
          mode: 'online'
        }
      });
    } else if (await this.capacitor.isNative() && await this.capacitor.getPlatform() == 'android') {
      await SocialLogin.initialize({
        google: {
          webClientId: socialGoogleWeb,
          mode: 'online'
        }
      });
    } else {
      await SocialLogin.initialize({
        apple: {
          clientId: socialAppleClient,
          redirectUrl: socialAppleRedirect
        },
        google: {
          webClientId: socialGoogleWeb,
          mode: 'online'
        }
      });
    }
  }

  //----------------------------------------------------------------------------
  // Create Account
  //----------------------------------------------------------------------------

  async createAccount() {
    this.firebase.setEvent('login_create');

    // Spinner
    this.showSpinner();

    // Request
    return await this.http.postRequest('/account', {
      language: this.capacitor.language,
      platform: this.capacitor.platform,
      push: this.capacitor.push

    }).then(async data => {
      if (data && data['token']) {
        await this.capacitor.setAuthorization(data['token']);
        this.router.navigateByUrl('/');
      }
      return false;
    });
  }

  //----------------------------------------------------------------------------
  // Verify Account
  //----------------------------------------------------------------------------

  async verifyAccount() {
    this.firebase.setEvent('login_verify');

    // Request
    return await this.updateAccount({
      email: this.email,
      name: this.name,
      verified: '1',
      privacy: '0'
    });
  }

  //----------------------------------------------------------------------------
  // Update Account
  //----------------------------------------------------------------------------

  async updateAccount(params: any) {
    this.firebase.setEvent('login_verified');

    // Spinner
    this.showSpinner();

    // Request
    return await this.http.putRequest('/account', params).then(data => {
      if (data) {
        this.closePage()
        this.events.refreshPages();
      }
      return true;
    });
  }

  //----------------------------------------------------------------------------
  // Login Account
  //----------------------------------------------------------------------------

  async loginAccount(params: any) {
    this.firebase.setEvent('login_success');

    // Request
    return await this.http.postRequest('/login', params).then(async data => {
      if (data?.token) {
        await this.capacitor.setAuthorization(data?.token);
        this.closePage();
        this.router.navigateByUrl('/');
        this.events.refreshPages();
      }
      return true;
    });
  }

  //----------------------------------------------------------------------------
  // Login Accept
  //----------------------------------------------------------------------------

  async loginAccept() {
    this.firebase.setEvent('login_accept');

    // Request
    await this.http.putRequest('/login', {
      token: this.token,
      platform: this.capacitor.platform,
      push: this.capacitor.push

    }).then(async data => {
      if (data && data?.token) {
        await this.capacitor.setAuthorization(data?.token);
        this.router.navigateByUrl('/');
        this.closePage();
        this.events.refreshPages();
      }
    });
  }

  //----------------------------------------------------------------------------
  // Login Email
  //----------------------------------------------------------------------------

  async loginEmail() {
    this.firebase.setEvent('login_email');
    this.showSpinner();

    // Request
    return await this.http.postRequest('/login', {
      type: 'email',
      email: this.email
    }).then(data => {
      if (data) {
        this.type = 'magic';
        this.spinner = false;
      }
    });
  }

  //----------------------------------------------------------------------------
  // Login Social
  //----------------------------------------------------------------------------

  async loginSocial(provider: any) {
    this.firebase.setEvent('login_' + provider);
    this.showSpinner();

    // Options
    let options = {};
    if(provider == 'apple') {
      options = {
        scopes: ['email', 'name'],
        nonce: Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10)
      };
    } else {
      options = {
        scopes: ['email', 'profile'],
        forceRefreshToken: true
      };
    }

    // Login
    const res = await SocialLogin.login({
      provider: provider,
      options: options
    });

    // Success
    if (res && 'idToken' in res.result) {

      // Data
      const data = {
        type: 'social',
        apple: provider == 'apple' ? res.result.profile.user != null ? res.result.profile.user : res.result.accessToken?.token : '',
        google: provider == 'google' ? res.result.profile.id : '',
        name: res.result.profile.givenName,
        email: res.result.profile.email,
        privacy: '1',
        verified: '0',
        avatar: res.result.profile.imageUrl ? res.result.profile.imageUrl : '',
        language: this.capacitor.language,
        platform: this.capacitor.platform,
        push: this.capacitor.push
      };

      // Verify
      if (res.result.profile && this.type == 'verify') {
        data.verified = '1';
        data.privacy = '0';
        this.firebase.setEvent('login_verify');
        return await this.updateAccount(data);
      }
  
      // Login
      if (res.result.profile && this.type == 'login') {
        this.firebase.setEvent('login_social');
        return await this.loginAccount(data);
      }
  
      // Error
      return false;
    } else {
      return false;
    }
  }

  //----------------------------------------------------------------------------
  // Open Login
  //----------------------------------------------------------------------------

  async openLogin() {
    this.firebase.setEvent('login_show');
    this.ionic.openPage(LoginPage, 'login');
  }

  //----------------------------------------------------------------------------
  // Show Spinner
  //----------------------------------------------------------------------------

  async showSpinner() {
    this.spinner = true;
    setTimeout(() => { this.spinner = false; }, 5000);
  }

  //----------------------------------------------------------------------------
  // Close View
  //----------------------------------------------------------------------------

  async closePage() {
    const modal = await this.modalLogin.getTop();
    if (modal) {
      modal.setCurrentBreakpoint(0);
    } else {
      await this.modalLogin.dismiss();
    }
    if(this.type = 'verify') this.events.refreshPages();
  }
}
