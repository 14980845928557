import { mapboxKey, mapboxLight, mapboxDark, pageMin, pageMap, pagePlace, pageStart, pageMax } from '../../../environments/environment';
import { ModalController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Feature, Geometry, Point } from 'geojson';
import * as mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import { 
  SearchPage,
  PlacePage
} from '../../pages';
import {
  CapacitorService,
  FirebaseService,
  EventsService,
  CacheService,
  IonicService,
  HttpService,
} from '..';

@Injectable({
  providedIn: 'root'
})
export class MapboxService {

  // Variables
  map!: mapboxgl.Map;
  markers: any = [];
  places: any;
  placeModal: any;
  placeBreakpoint = pagePlace;
  mapModal: any;
  mapBreakpoint: number = pageStart;
  mapButtons: boolean = true;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public modalMapbox: ModalController,
    public firebase: FirebaseService,
    public events: EventsService,
    public caches: CacheService,
    public ionic: IonicService,
    public http: HttpService,
    public router: Router
  ) { }

  //----------------------------------------------------------------------------
  // Init Map
  //----------------------------------------------------------------------------

  async initMap(): Promise<mapboxgl.Map | null> {
    if (!this.capacitor.map) return null;

    // Scheme
    let scheme = await this.capacitor.getAppearance();
    let style = window.matchMedia('(prefers-color-scheme: dark)');
    let appearance = scheme != 'system' ? scheme : style.matches ? 'dark' : 'light';
    style.addListener(async (e: any) => {
      if (scheme == 'system') this.setStyle(e.matches ? 'dark' : 'light');
    });

    // Setup
    this.map = new mapboxgl.Map({
      container: 'map',
      accessToken: mapboxKey,
      style: appearance == 'dark' ? mapboxDark : mapboxLight,
      center: [4.282741, 47.201187],
      antialias: true,
      maxZoom: 18,
      minZoom: 2,
      pitch: 0,
      zoom: 3,
    });

    // Configuration
    this.setConfiguration();
    this.setInteractions();

    // Return
    return this.map;
  }

  //----------------------------------------------------------------------------
  // Set Configuration
  //----------------------------------------------------------------------------

  async setConfiguration() {
    this.map.setMaxPitch(0);
    this.map.setMinPitch(0);
    this.map.touchPitch.disable();
    this.map.dragRotate.disable();
    this.map.touchZoomRotate.disableRotation();
    this.map.setPadding({
      top: 100,
      right: 100,
      bottom: this.capacitor.tablet ? 180 : 300,
      left: this.capacitor.tablet ? 500 : 100
    });
  }

  //----------------------------------------------------------------------------
  // Set Interactions
  //----------------------------------------------------------------------------

  async setInteractions() {

    // Zoom & Drag
    ['dragstart', 'zoomstart'].forEach((event) => {
      this.map.on(event, () => {
        if (window.innerWidth <= 768) {
          this.mapModal.setCurrentBreakpoint(pageMap);
        }
      });
    });

    // Click Clusters
    this.map.on('click', 'map-clusters', (e) => {
      e.originalEvent.preventDefault();
      e.originalEvent.stopPropagation();
      
      // Zoom
      this.firebase.setEvent('map_cluster');
      const features = this.map.queryRenderedFeatures(e.point, { layers: ['map-clusters'] });
      const clusterId = features[0]?.properties?.['cluster_id'];
      (this.map.getSource('map-places') as mapboxgl.GeoJSONSource).getClusterExpansionZoom(clusterId, (err: any, zoom: any) => {
        if (err) return;
        const feature: Feature<Geometry> = features[0];
        if (feature.geometry.type === "Point") {
          const point = feature.geometry as Point;
          this.map.easeTo({
            center: point.coordinates as [number, number],
            zoom: zoom
          });
        }
      });
    });
      
    // Click Places
    this.map.on('click', 'map-places', (e) => {
      if (!e.features || e.features.length === 0) return;
      e.originalEvent.preventDefault();
      e.originalEvent.stopPropagation();

      // Open
      this.firebase.setEvent('map_place');
      this.ionic.openView(
        e.features[0].properties?.['type'],
        e.features[0].properties?.['id']
      );
    });
      
    // Click View
    this.map.on('click', 'view-places', (e) => {
      if (!e.features || e.features.length === 0) return;
      e.originalEvent.preventDefault();
      e.originalEvent.stopPropagation();

      // Open
      this.firebase.setEvent('map_place');
      this.ionic.openView(
        e.features[0].properties?.['type'],
        e.features[0].properties?.['id']
      );
    });

    // Mouse Events
    this.map.on('mouseenter', 'map-clusters', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
    this.map.on('mouseleave', 'map-clusters', () => {
      this.map.getCanvas().style.cursor = '';
    });
    this.map.on('mouseenter', 'map-places', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
    this.map.on('mouseleave', 'map-places', () => {
      this.map.getCanvas().style.cursor = '';
    });
    this.map.on('mouseenter', 'view-places', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
    this.map.on('mouseleave', 'view-places', () => {
      this.map.getCanvas().style.cursor = '';
    });

  }

  //----------------------------------------------------------------------------
  // Set Style
  //----------------------------------------------------------------------------

  async setStyle(scheme: string) {
    this.map.setStyle(scheme == 'dark' ? mapboxDark : mapboxLight);
  }

  //----------------------------------------------------------------------------
  // Load Map
  //----------------------------------------------------------------------------

  async showMap(data: any) {
    if (!this.capacitor.map) return;
    if (data?.map) setTimeout(() => {
      this.places = data?.places;
      this.setCountries(data?.map?.countries);
      this.setRegions(data?.map?.regions);
      this.setCities(data?.map?.cities);
      this.setPlaces(data?.places);
      this.setLanguage();
      this.resizeMap();
    }, 200);
  }

  //----------------------------------------------------------------------------
  // Show View
  //----------------------------------------------------------------------------

  async showView(data: any) {
    if (!this.capacitor.map) return;
    if (data?.places?.features.length == 0) return;

    // Hide
    this.mapButtons = false;
    this.map.setLayoutProperty('countries-open', 'visibility', 'none');
    this.map.setLayoutProperty('countries-added', 'visibility', 'none');
    this.map.setLayoutProperty('countries-completed', 'visibility', 'none');
    this.map.setLayoutProperty('countries-active', 'visibility', 'none');
    this.map.setLayoutProperty('regions-added', 'visibility', 'none');
    this.map.setLayoutProperty('regions-completed', 'visibility', 'none');
    this.map.setLayoutProperty('cities-added', 'visibility', 'none');
    this.map.setLayoutProperty('cities-completed', 'visibility', 'none');
    this.map.setLayoutProperty('map-clusters', 'visibility', 'none');
    this.map.setLayoutProperty('map-counters', 'visibility', 'none');
    this.map.setLayoutProperty('map-places', 'visibility', 'none');

    // Remove
    if (this.map.getLayer('view-routes')) this.map.removeLayer('view-routes');
    if (this.map.getSource('view-routes')) this.map.removeSource('view-routes');
    if (this.map.getLayer('view-places')) this.map.removeLayer('view-places');
    if (this.map.getSource('view-places')) this.map.removeSource('view-places');

    // Set
    this.setRoutes(data?.places);
    this.setPlace(data?.places);
  }

  //----------------------------------------------------------------------------
  // Hide View
  //----------------------------------------------------------------------------

  async hideView() {
    if (!this.capacitor.map) return;

    // Visible
    this.mapButtons = true;
    this.map.setLayoutProperty('countries-open', 'visibility', 'visible');
    this.map.setLayoutProperty('countries-added', 'visibility', 'visible');
    this.map.setLayoutProperty('countries-completed', 'visibility', 'visible');
    this.map.setLayoutProperty('countries-active', 'visibility', 'visible');
    this.map.setLayoutProperty('regions-added', 'visibility', 'visible');
    this.map.setLayoutProperty('regions-completed', 'visibility', 'visible');
    this.map.setLayoutProperty('cities-added', 'visibility', 'visible');
    this.map.setLayoutProperty('cities-completed', 'visibility', 'visible');
    this.map.setLayoutProperty('map-clusters', 'visibility', 'visible');
    this.map.setLayoutProperty('map-counters', 'visibility', 'visible');
    this.map.setLayoutProperty('map-places', 'visibility', 'visible');

    // Remove
    if (this.map.getLayer('view-routes')) this.map.removeLayer('view-routes');
    if (this.map.getSource('view-routes')) this.map.removeSource('view-routes');
    if (this.map.getLayer('view-places')) this.map.removeLayer('view-places');
    if (this.map.getSource('view-places')) this.map.removeSource('view-places');

    // Set
    this.setPlaces(this.places);
  }

  //----------------------------------------------------------------------------
  // Set Place
  //----------------------------------------------------------------------------

  async setPlace(places: any) {
    if (!this.map.getSource('view-places') && places) {

      // Source
      this.map.addSource('view-places', {
        type: 'geojson',
        data: places
      });

      // Places
      this.map.addLayer({
        id: 'view-places',
        type: 'symbol',
        source: 'view-places',
        layout: {
          'icon-image': [
            'case',
            ['==', ['get', 'status'], 'completed'],
            'city-completed',
            'city-added'
          ],
          'icon-size': .7,
          'icon-anchor': 'center',
          'icon-allow-overlap': true
        }
      });

      // Bounds
      var bounds = new mapboxgl.LngLatBounds();
      places?.features.forEach(function (feature: any) {
        bounds.extend(feature.geometry.coordinates);
      });
      if (bounds) this.map.fitBounds(bounds, {
        maxZoom: 6,
        linear: true
      });
    }
  }

  //----------------------------------------------------------------------------
  // Set Routes
  //----------------------------------------------------------------------------

  async setRoutes(places: any) {
    if (!this.map.getSource('view-routes') && places) {

      this.map.addSource('view-routes', {
        type: 'geojson',
        data: places
      });

      this.map.addLayer({
        id: 'view-routes',
        type: 'line',
        source: 'view-routes',
        filter: ['==', '$type', 'LineString'],
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#ACBF95',
          'line-width': 2
        }
      });
    }
  }

  //----------------------------------------------------------------------------
  // Set Places
  //----------------------------------------------------------------------------

  async setPlaces(places: any) {
    if (!this.map.getSource('map-places') && places) {

      // Source
      this.map.addSource('map-places', {
        type: 'geojson',
        data: places,
        cluster: true, 
        clusterMaxZoom: 8,
        clusterRadius: 40 
      });
  
      // Cluster
      this.map.addLayer({
        id: 'map-clusters',
        type: 'circle',
        source: 'map-places',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': this.capacitor.scheme == 'dark' ? '#223336' : '#B5CEAD',
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            10,
            100, 15,
            750, 20
          ],
          'circle-stroke-width': 1,
          'circle-stroke-color': this.capacitor.scheme == 'dark' ? '#101C20' : '#FFF'
        }
      });
  
      // Counter
      this.map.addLayer({
        id: 'map-counters',
        type: 'symbol',
        source: 'map-places',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['Open Runde Bold'],
          'text-size': 11
        },
        paint: {
          'text-color': '#FFF'
        }
      });

      // Marker
      this.map.addLayer({
        id: 'map-places',
        type: 'symbol',
        source: 'map-places',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': [
            'case',
            ['==', ['get', 'status'], 'completed'],
            'city-completed',
            'city-added'
          ],
          'icon-size': .7,
          'icon-anchor': 'center',
          'icon-allow-overlap': true
        }
      });

      // Thumbnails
      this.setThumbnails(places);
    }
  }

  //----------------------------------------------------------------------------
  // Set Thumbmnails
  //----------------------------------------------------------------------------

  async setThumbnails(places: any) {
    await places.features.map(async (feature: any) => {
      const imageUrl = feature.properties?.thumbnail;
      const id = feature.properties?.id;
      if (imageUrl) {
        await this.caches.getImage(imageUrl, true, true).then(img => {
          this.map.loadImage(img, (error, thumbnail: any) => {
            if (!error) {
              this.map.addImage(id, thumbnail);
            }
          });
        });
      }
    });
    this.map.setLayoutProperty('map-places', 
      'icon-image', [
        'case',
        ['!=', ['get', 'thumbnail'], ''],
        ['get', 'id'],
        ['case', 
          ['==', ['get', 'status'], 'completed'],
          'city-completed',
          'city-added'
        ]
      ]
    );
  }

  //----------------------------------------------------------------------------
  // Open Country
  //----------------------------------------------------------------------------

  async openCountry(data: any, status: string) {
    this.firebase.setEvent('map_country');

    // Map
    var bbox = turf.bbox(data);
    this.map.setFilter('countries-active', ['==', 'code', data.properties.code]);
    this.map.fitBounds([[bbox[0], bbox[1]], [bbox[2], bbox[3]]], { maxZoom: 6 });

    //  Breakpoints
    this.mapModal.setCurrentBreakpoint(pageMap);

    // Create
    this.placeModal = await this.modalMapbox.create({
      component: PlacePage,
      initialBreakpoint: pagePlace,
      breakpoints: [pageMin, pagePlace, pageMax],
      backdropBreakpoint: 1,
      showBackdrop: false,
      backdropDismiss: false,
      canDismiss: true,
      animated: true,
      handle: true,
      componentProps: {
        id: data.properties?.id,
        status: status
      }
    });

    // Listener
    this.placeModal.addEventListener('ionBreakpointDidChange', (event: any) => {
      this.placeBreakpoint = event.detail.breakpoint;
    });

    // Dismiss
    this.placeModal.onDidDismiss().then(() => {
      this.placeBreakpoint = pageMap;
    });

    // Present
    if (await this.modalMapbox.getTop()) this.modalMapbox.dismiss();
    await this.placeModal.present();
  }

  //----------------------------------------------------------------------------
  // Dismiss Country
  //----------------------------------------------------------------------------

  async dismissCountry() {
    this.map.setFilter('countries-active', ['==', 'code', '']);
    const modal = await this.modalMapbox.getTop();
    if (modal) {
      modal.setCurrentBreakpoint(0);
      await this.ionic.setTimeout(500);
      await this.modalMapbox.dismiss();
      this.events.refreshPages();
    }
  }

  //----------------------------------------------------------------------------
  // Set Countries
  //----------------------------------------------------------------------------

  async setCountries(data: any) {

    // Added
    this.map.setFilter('countries-added', ['==', 'code', '']);
    this.map.setFilter('countries-added', data['added']);
    this.map.on('click', 'countries-added', async (e: any) => {

      const featuresAtPointer = this.map.queryRenderedFeatures(e.point);
      const markerFeature = featuresAtPointer.find(
        (feature) => feature?.layer?.id === 'map-places' || feature?.layer?.id === 'map-clusters'
      );

      if (markerFeature) {
        e.originalEvent.preventDefault();
        return;
      }

      if (!e.originalEvent.defaultPrevented) {
        e.originalEvent.preventDefault();
        await this.openCountry(e.features[0], 'added');
      }
    });

    // Completed
    this.map.setFilter('countries-completed', ['==', 'code', '']);
    this.map.setFilter('countries-completed', data['completed']);
    this.map.on('click', 'countries-completed', async (e: any) => {

      const featuresAtPointer = this.map.queryRenderedFeatures(e.point);
      const markerFeature = featuresAtPointer.find(
        (feature) => feature?.layer?.id === 'map-places' || feature?.layer?.id === 'map-clusters'
      );

      if (markerFeature) {
        e.originalEvent.preventDefault();
        return;
      }

      if (!e.originalEvent.defaultPrevented) {
        e.originalEvent.preventDefault();
        await this.openCountry(e.features[0], 'completed');
      }
    });

    // Open
    this.map.setFilter('countries-active', ['==', 'code', '']);
    this.map.on('click', 'countries-open', async (e: any) => {

      const featuresAtPointer = this.map.queryRenderedFeatures(e.point);
      const markerFeature = featuresAtPointer.find(
        (feature) => feature?.layer?.id === 'map-places' || feature?.layer?.id === 'map-clusters'
      );

      if (markerFeature) {
        e.originalEvent.preventDefault();
        return;
      }

      if (!e.originalEvent.defaultPrevented) {
        e.originalEvent.preventDefault();
        await this.openCountry(e.features[0], 'open');
      }
    });

    // Dismiss
    this.map.on('click', 'water', async (e: any) => {
      if (!e.originalEvent.defaultPrevented) {
        e.originalEvent.preventDefault();
        this.dismissCountry();
      }
    });
  }

  //----------------------------------------------------------------------------
  // Set Regions
  //----------------------------------------------------------------------------

  async setRegions(data: any) {
    this.map.setFilter('regions-added', data['added']);
    this.map.setFilter('regions-completed', data['completed']);
  }

  //----------------------------------------------------------------------------
  // Set Cities
  //----------------------------------------------------------------------------

  async setCities(data: any) {
    this.map.setFilter('cities-added', data['added']);
    this.map.setFilter('cities-completed', data['completed']);
  }

  //----------------------------------------------------------------------------
  // Update Scratch
  //----------------------------------------------------------------------------

  async addMap(id: string) {

    // Request
    this.http.isLoading(id);
    await this.http.putRequest('/view/' + id, { status: 'added' });
    await this.ionic.setTimeout(500);
    this.events.refreshPages();
  }

  //----------------------------------------------------------------------------
  // Update Scratch
  //----------------------------------------------------------------------------

  async completeMap(id: string) {

    // Request
    this.http.isLoading(id);
    await this.http.putRequest('/view/' + id, { status: 'completed' });
    await this.ionic.setTimeout(500);
    this.events.refreshPages();
  }

  //----------------------------------------------------------------------------
  // Delete Scratch
  //----------------------------------------------------------------------------

  async deleteMap(id: string) {

    // Request
    this.http.isLoading(id);
    await this.http.deleteRequest('/view/' + id, {});
    await this.ionic.setTimeout(500);
    this.events.refreshPages();
  }

  //----------------------------------------------------------------------------
  // Add City
  //----------------------------------------------------------------------------

  async suggestMap(iso: string) {
    const data = await this.ionic.openPage(SearchPage, 'places', iso);
    if (data) await this.ionic.showAlert('Success', this.translate.instant('CityAdded'));
    this.events.refreshPages();
  }

  //----------------------------------------------------------------------------
  // Show Geolocation
  //----------------------------------------------------------------------------

  async showGeolocation(coordinates: any) {
    if (coordinates) {
      this.map.flyTo({
        center: [coordinates.longitude, coordinates.latitude],
        zoom: 8,
        speed: 2,
        curve: 1.4,
        easing: (t) => t,
      });
      const markerElement = document.createElement('div');
      markerElement.className = 'user-location-marker';
      new mapboxgl.Marker({ element: markerElement })
        .setLngLat([coordinates.longitude, coordinates.latitude])
        .addTo(this.map);
    }
  }

  //----------------------------------------------------------------------------
  // Modal Height
  //----------------------------------------------------------------------------

  async modalHeight(value: number) {
    this.placeModal.setCurrentBreakpoint(value);
  }

  //----------------------------------------------------------------------------
  // Modal Place Breakpoint
  //----------------------------------------------------------------------------

  async modalPlaceBreakpoint() {
    let value = this.placeBreakpoint == pageMax ? pagePlace : pageMax;
    this.placeModal.setCurrentBreakpoint(value);
  }

  //----------------------------------------------------------------------------
  // Modal Map Breakpoint
  //----------------------------------------------------------------------------

  async modalMapBreakpoint() {
    if (this.capacitor.map) {
      let value = this.mapBreakpoint == pageMax ? pageMap : pageMax;
      this.mapModal.setCurrentBreakpoint(value);
    }
  }

  //----------------------------------------------------------------------------
  // Resize Map
  //----------------------------------------------------------------------------

  async resizeMap() {
    setTimeout(() => {
      this.map.resize();
    }, 100);
  }

  //----------------------------------------------------------------------------
  // Set language
  //----------------------------------------------------------------------------

  async setLanguage() {
    const lang = await this.capacitor.getLanguage();
    this.map.setLayoutProperty('countries-labels', 'text-field', [
      'get', 'title_' + lang
    ]);
    this.map.setLayoutProperty('regions-labels', 'text-field', [
      'get', 'title_' + lang
    ]);
    this.map.setLayoutProperty('cities-labels', 'text-field', [
      'get', 'title_' + lang
    ]);
  }
}
