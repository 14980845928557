<ion-header mode="ios">
  <ion-toolbar>
    <ion-title>
      <span class="flag" [style]="'background-image: url(assets/flags/' + data?.view?.iso.toLowerCase() + '.svg);'" *ngIf="data"></span>
      <ion-skeleton-text class="text" [animated]="true" *ngIf="!data"></ion-skeleton-text>
      {{ data?.view?.title }}
    </ion-title>
    <ion-buttons slot="end">
      <!-- FUTURE
      <ion-button class="expand">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/home-02.svg'"></ion-icon>
      </ion-button>
      -->
      <ion-button class="expand" (click)="expandView()" [disabled]="this.expanded">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/layout-bottom.svg'" [hidden]="this.mapbox.placeBreakpoint == pagePlace"></ion-icon>
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/layout-top.svg'" [hidden]="this.mapbox.placeBreakpoint == pageMax"></ion-icon>
      </ion-button>
      <ion-button class="close" (click)="mapbox.dismissCountry()">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="actions">
    <div class="buttons">
      <button ion-button class="cta big green" [disabled]="!data || (loading$ | async) == id"
        (click)="data?.view?.status == 'completed' ? deletePlace($event) : completePlace($event)" 
        [class.active]="data?.view?.status == 'completed'"
        [class.inactive]="data?.view?.status == 'added'">
        <ion-icon src="/assets/icons/color/flag-01.svg" *ngIf="(loading$ | async) != id"></ion-icon>
        <span *ngIf="(loading$ | async) != id">{{ 'Visited' | translate }}</span>
        <ion-spinner name="circular" *ngIf="(loading$ | async) == id"></ion-spinner>
      </button>
      <button ion-button class="cta big blue" [disabled]="!data || (loading$ | async) == id"
        (click)="data?.view?.status == 'added' ? deletePlace($event) : addPlace($event)"
        [class.active]="data?.view?.status == 'added'"
        [class.inactive]="data?.view?.status == 'completed'">
        <ion-icon src="/assets/icons/color/heart.svg" *ngIf="(loading$ | async) != id"></ion-icon>
        <span *ngIf="(loading$ | async) != id">{{ 'Visit' | translate }}</span>
        <ion-spinner name="circular" *ngIf="(loading$ | async) == id"></ion-spinner>
      </button>
    </div>
  </ion-toolbar>
  <ion-toolbar class="tabs">
    <ion-segment [(ngModel)]="type" mode="md" (ionChange)="switchToggle()">
      <ion-segment-button value="regions">
        <ion-label>
          {{ 'Regions' | translate }}
          <div class="badge">
            <span class="number">{{ data?.view?.regions }}</span>
          </div>
        </ion-label>
      </ion-segment-button>
      <ion-segment-button value="cities">
        <ion-label>
          {{ 'Cities' | translate }}
          <div class="badge">
            <span class="number">{{ data?.view?.cities }}</span>
          </div>
        </ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<ion-content>
  
  <!-- Regions -->

  <ion-grid>
    <ion-list class="padding">
      <ion-input #searchbar class="searchbar" mode="ios" [formControl]="search" [(ngModel)]="query" [clearInput]="true" placeholder="{{ 'Search' | translate }}"></ion-input>
    </ion-list>
  </ion-grid>

  <!-- Premium -->

  <ion-grid *ngIf="data && !data?.premium && data?.limits?.goals?.left">
    <ion-list class="padding">
      <div class="premium" (click)="openPremium()">
        <div class="text" [class.danger]="data?.limits?.places?.maximum">
          <div class="statistics">
            {{ 'PremiumPlacesLeft' | translate: { 'count': data?.limits?.places?.left } }}
          </div>
          <div class="types">
            {{ 'PremiumGo' | translate }}
          </div>
        </div>
        <div class="bottom">
          <div class="progress">
            <div class="line" [class.danger]="data?.limits?.places?.maximum" [ngStyle]="{'width': data?.limits?.places?.progress + '%'}"></div>
          </div>
        </div>
      </div>
    </ion-list>
  </ion-grid>

  <!-- Regions -->

  <ion-grid *ngIf="type == 'regions'">
    <ion-list>
      <app-item [data]="data" *ngFor="let data of data?.regions; index as i; trackBy: ionic.trackItem"></app-item>
    </ion-list>
  </ion-grid>

  <!-- Cities -->

  <ion-grid *ngIf="type == 'cities'">
    <ion-list>
      <app-item [data]="data" *ngFor="let data of cities; index as i; trackBy: ionic.trackItem"></app-item>
      <ion-grid class="suggest">
        <button ion-button class="cta medium mediumgrey outline" (click)="mapbox.suggestMap(data?.view?.iso)">
          {{ 'SuggestCity' | translate }}
        </button>
      </ion-grid>
    </ion-list>
  </ion-grid>
  
  <!-- Infinite -->

  <ion-infinite-scroll (ionInfinite)="doInfinite($event)" *ngIf="cities && data?.cities.length >= 200">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>

</ion-content>