import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import {
  AssistantPage,
  ManagePage,
  PremiumPage
} from '../../pages';
import {
  CapacitorService,
  FirebaseService,
  FriendsService,
  IonicService,
  GoalsService,
  HttpService,
  MapboxService,
  EventsService
} from '../../services';

@Component({
  standalone: false,
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ItemComponent implements OnInit {

  public loading$ = this.http.loading.asObservable();
  public removing$ = this.http.removing.asObservable();

  @Output() changeToggle = new EventEmitter<boolean>();
  @Output() changeSelect = new EventEmitter<string>();

  @Input() data: any = [];
  @Input() cache: any;
  @Input() options: any;
  @Input() reorder: any;

  @Input() label: string = '';
  @Input() sublabel: string = '';
  @Input() type: string = '';
  @Input() icon: string = '';
  @Input() sort: string = '';
  @Input() value: string = '';
  @Input() select: string = '';
  @Input() class: string = '';

  @Input() spinner: boolean = false;
  @Input() lock: boolean = false;
  @Input() date: boolean = false;
  @Input() toggle: boolean = false;
  @Input() disabled: boolean = false;
  @Input() premium: boolean = false;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public modalItem: ModalController,
    public firebase: FirebaseService,
    public friends: FriendsService,
    public places: MapboxService,
    public events: EventsService,
    public ionic: IonicService,
    public goals: GoalsService,
    public http: HttpService,
    public router: Router
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  async ngOnInit() {}

  //----------------------------------------------------------------------------
  // Open Item
  //----------------------------------------------------------------------------

  async openItem() {
    if (this.data?.id && this.data?.search && !this.data?.view) {
      this.firebase.setEvent('search_select');
      this.modalItem.dismiss({
        'id': this.data?.id,
        'title': this.data?.title,
        'refer': this.data?.refer,
        'image': this.data?.image,
        'icon': this.data?.icon,
        'type': this.data?.type
      });
    } else if (this.data?.view && this.data?.id && this.data?.type) {
      this.firebase.setEvent('view_open');
      this.firebase.setEvent(this.data?.type + '_open');
      this.ionic.openView(this.data?.type, this.data?.id);
    }
  }

  //----------------------------------------------------------------------------
  // Open Manage
  //----------------------------------------------------------------------------

  async openManage(type: string, event: Event) {
    this.firebase.setEvent('view_add');
    this.firebase.setEvent('view_add_' + type);
    this.events.cancelEvent(event);
    this.ionic.openPage(ManagePage, type, '', this.data);
  }

  //----------------------------------------------------------------------------
  // Open Profile
  //----------------------------------------------------------------------------

  async openProfile(event: Event) {
    if(this.data?.display == 'social') {
      this.firebase.setEvent('view_profile');
      this.events.cancelEvent(event);
      this.ionic.openView('user', this.data?.user);
    }
  }

  //----------------------------------------------------------------------------
  // Edit Item
  //----------------------------------------------------------------------------

  async editItem(event: Event) {
    this.firebase.setEvent('view_options');
    this.events.cancelEvent(event);

    // Options
    let array = [];

    // Assistant
    if ((this.data?.edit) && this.data?.type == 'goal') array.push({
      text: this.translate.instant('AssistantAI'),
      cssClass: 'action-sheet-icon assistant',
      handler: () => {
        this.firebase.setEvent('view_assistant');
        this.ionic.openPage(AssistantPage, this.data?.type, this.data?.id);
      }
    });

    // Edit
    if (this.data?.edit) array.push({
      text: this.translate.instant('Edit'),
      cssClass: 'action-sheet-icon edit',
      handler: () => {
        this.firebase.setEvent('view_edit');
        this.ionic.openPage(ManagePage, this.data?.type, this.data?.id);
      }
    });

    // Status
    if (this.data?.edit && (this.data?.type == 'goal' || this.data?.type == 'task')) array.push({
      text: this.data?.status == 'completed' ? this.translate.instant('Open') : this.translate.instant('Complete'),
      cssClass: 'action-sheet-icon status',
      handler: () => {
          this.firebase.setEvent('view_status');
         this.goals.setStatus(this.data?.id, this.data?.status);
      }
    });

    // Rename
    if (this.data?.edit) array.push({
      text: this.translate.instant('Rename'),
      cssClass: 'action-sheet-icon rename',
      handler: () => {
        this.firebase.setEvent('view_rename');
        this.goals.setName(this.data?.id, this.data?.title);
      }
    });

    // Move
    if ((this.data?.type == 'goal' || this.data?.type == 'task') && this.data?.owner) array.push({
      text: this.translate.instant('Move'),
      cssClass: 'action-sheet-icon move',
      handler: () => {
        this.firebase.setEvent('view_move');
        this.goals.setParent(this.data?.id, this.data?.bucket, this.data?.trip, this.data?.goal);
      }
    });

    // Profile
    if (this.data?.type == 'user') array.push({
      text: this.translate.instant('Profile'),
      cssClass: 'action-sheet-icon profile',
      handler: () => {
        this.firebase.setEvent('view_profile');
        this.ionic.openView('user', this.data?.id);
      }
    });

    // Follow
    if (this.data?.type == 'user' && !this.data?.follow) array.push({
      text: this.translate.instant('Follow'),
      cssClass: 'action-sheet-icon follow',
      handler: () => {
        this.firebase.setEvent('friend_add');
        this.friends.addFriend(this.data?.id);
      }
    });

    // Unfollow
    if (this.data?.type == 'user' && this.data?.follow) array.push({
      text: this.translate.instant('Unfollow'),
      cssClass: 'action-sheet-icon unfollow',
      role: 'destructive',
      handler: () => {
        this.firebase.setEvent('friend_remove');
        this.friends.removeFriend(this.data?.id);
      }
    });

    // Delete
    if (this.data?.edit) array.push({
      text: this.translate.instant('Delete'),
      cssClass: 'action-sheet-icon delete',
      role: 'destructive',
      handler: () => {
        this.firebase.setEvent('view_delete');
        this.goals.deleteItem(this.data?.id);
      }
    });

    // Sheet
    return await this.ionic.showSheet(this.data?.title, array);
  }

  //----------------------------------------------------------------------------
  // Delete Item
  //----------------------------------------------------------------------------

  async deleteItem(event: Event) {
    this.firebase.setEvent('view_delete');
    this.events.cancelEvent(event);
    this.goals.deleteItem(this.data?.id);
  }

  //----------------------------------------------------------------------------
  // Complete Item
  //----------------------------------------------------------------------------

  async completeItem(event: Event) {
    this.firebase.setEvent('view_status');
    this.events.cancelEvent(event);
    if(this.data?.edit) {
      this.goals.setStatus(this.data?.id, this.data?.status);
      this.data.status = this.data?.status == 'add' ? 'completed' : 'add';
    }
  }

  //----------------------------------------------------------------------------
  // Add Place
  //----------------------------------------------------------------------------

  async addPlace(event: Event) {
    this.firebase.setEvent('places_add');
    this.events.cancelEvent(event);
    await this.places.addMap(this.data?.id);
    this.data.status = 'added';
  }

  //----------------------------------------------------------------------------
  // Complete Place
  //----------------------------------------------------------------------------

  async completePlace(event: Event) {
    this.firebase.setEvent('places_complete');
    this.events.cancelEvent(event);
    await this.places.completeMap(this.data?.id);
    this.data.status = 'completed';
  }

  //----------------------------------------------------------------------------
  // Delete Place
  //----------------------------------------------------------------------------

  async deletePlace(event: Event) {
    this.firebase.setEvent('places_delete');
    this.events.cancelEvent(event);
    await this.places.deleteMap(this.data?.id);
    this.data.status = '';
  }

  //----------------------------------------------------------------------------
  // Approve Friend
  //----------------------------------------------------------------------------

  async approveFriend(event: Event) {
    this.firebase.setEvent('friend_approve');
    this.events.cancelEvent(event);
    this.friends.approveFriend(this.data?.id);
  }

  //----------------------------------------------------------------------------
  // Deny Friend
  //----------------------------------------------------------------------------

  async denyFriend(event: Event) {
    this.firebase.setEvent('friend_deny');
    this.events.cancelEvent(event);
    this.friends.denyFriend(this.data?.id);
  }

  //----------------------------------------------------------------------------
  // Toggle Invite
  //----------------------------------------------------------------------------

  async toggleInvite() {
    this.http.isLoading(this.data?.id);
    this.firebase.setEvent(this.data?.invite ? 'invite_enable' : 'invite_disable');
    this.data.invite = await this.friends.toggleInvite(this.data, this.data?.invite);
  }

  //----------------------------------------------------------------------------
  // Accept Invite
  //----------------------------------------------------------------------------

  async acceptInvite(event: Event) {
    this.firebase.setEvent('invite_accept');
    this.events.cancelEvent(event);
    this.friends.acceptInvite(this.data?.id, this.data?.token);
  }

  //----------------------------------------------------------------------------
  // Share Invite
  //----------------------------------------------------------------------------

  async shareInvite(event: Event) {
    this.firebase.setEvent('invite_share');
    this.events.cancelEvent(event);
    this.friends.shareInvite(this.data?.invite, this.data?.title, this.data?.name);
  }

  //----------------------------------------------------------------------------
  // User Invite
  //----------------------------------------------------------------------------

  async userInvite(event: Event) {
    this.firebase.setEvent('invite_search');
    this.events.cancelEvent(event);
    this.friends.userInvite(this.data?.id, this.data?.title);
  }

  //----------------------------------------------------------------------------
  // Decline Invite
  //----------------------------------------------------------------------------

  async declineInvite(event: Event) {
    this.firebase.setEvent('invite_decline');
    this.events.cancelEvent(event);
    this.friends.declineInvite(this.data?.id, this.data?.token);
  }

  //----------------------------------------------------------------------------
  // Delete Invite
  //----------------------------------------------------------------------------

  async deleteInvite(event: Event) {
    this.firebase.setEvent('invite_delete');
    this.events.cancelEvent(event);
    this.friends.deleteInvite(this.data?.token, this.data?.id);
  }

  //----------------------------------------------------------------------------
  // Like Feed
  //----------------------------------------------------------------------------

  async likeFeed(event: Event) {
    this.firebase.setEvent('friend_like');
    this.events.cancelEvent(event);
    this.friends.likeFeed(this.data?.id);
  }

  //----------------------------------------------------------------------------
  // Open Premium
  //----------------------------------------------------------------------------

  async openPremium() {
    this.firebase.setEvent('item_premium');
    this.ionic.openPage(PremiumPage, 'account');
  }
}
