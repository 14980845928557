import { appDebug, appServer, appSecurity, appNumber, linkApple, linkGoogle } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { CapacitorHttp } from '@capacitor/core';
import { PremiumPage, LoginPage } from '../../pages';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import {
  CapacitorService,
  FirebaseService,
  CacheService,
  IonicService
} from '..';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  public removing: BehaviorSubject<string> = new BehaviorSubject<string>('')
  public isRemoving(state: string): void {
    this.removing.next(state);
  }

  public loading: BehaviorSubject<string> = new BehaviorSubject<string>('')
  public isLoading(state: string): void {
    this.loading.next(state);
  }

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public capacitor: CapacitorService,
    public firebase: FirebaseService,
    public translate: TranslateService,
    public cache: CacheService,
    public ionic: IonicService,
    public router: Router
  ) { }

  //----------------------------------------------------------------------------
  // Http Requests
  //----------------------------------------------------------------------------

  async getRequest(route: string, cache: string = '') {
    return await this.doRequest('get', route, {}, cache);
  }

  async postRequest(route: string, data: any = {}) {
    return await this.doRequest('post', route, data);
  }

  async putRequest(route: string, data: any = {}) {
    return await this.doRequest('put', route, data);
  }

  async uploadRequest(route: string, image: any = {}) {
    return await this.uploadImage(route, image);
  }

  async deleteRequest(route: string, data: any = {}) {
    return await this.doRequest('delete', route, data);
  }

  //----------------------------------------------------------------------------
  // Do Request
  //----------------------------------------------------------------------------

  async doRequest(method: string, route: string, data?: any, cache?: string): Promise<any> {
    
    // Request
    const url = route.split(' ').join('%20');
    const array: any = {
      method: method.toUpperCase(),
      url: appServer + url,
      params: {},
      headers: {
        'Version': appNumber,
        'Push': this.capacitor.push,
        'App': this.capacitor.native ? appSecurity : '',
        'Authorization': await this.capacitor.getAuthorization(),
        'Content-Language': this.capacitor.language,
        'Content-Scheme': this.capacitor.scheme,
        'Content-Type': method == 'get' || data.filename ?
        'application/json' : 'application/x-www-form-urlencoded'
      }
    };

    // Update
    if (method == 'post' || method == 'put') {
      array.data = JSON.parse(JSON.stringify(data));
      if (array.data.title) array.data.title = encodeURI(array.data?.title);
      if (array.data.story) array.data.story = encodeURI(array.data?.story);
      if (array.data.note) array.data.note = encodeURI(array.data?.note);
      if (array.data.title) array.data.title = array.data?.title.replaceAll('&', 'M%26M');
      if (array.data.story) array.data.story = array.data?.story.replaceAll('&', 'M%26M');
      if (array.data.note) array.data.note = array.data?.note.replaceAll('&', 'M%26M');
      if (array.data.goals && method == 'post') array.data.goals = array.data.goals && array.data.goals.length != 0 ? JSON.stringify(array.data.goals) : '';
    }

    // Debug
    if (appDebug) console.log(
      '[HTTP:' + method.toUpperCase() + ']',
      route, array.data ? array.data : []
    );

    // Success
    return await CapacitorHttp.request(array).then(async resp => {
      return await this.returnRequest(resp, method, cache);
    });
  }
  
  //----------------------------------------------------------------------------
  // Upload Image
  //----------------------------------------------------------------------------

  async uploadImage(route: string, image: any) {
    try {
      let blob = await fetch(image.webPath).then(r => r.blob());
      const base64data = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const result = reader.result?.toString().split(',')[1];
          result ? resolve(result) : reject("Base64 conversion failed");
        };
        reader.onerror = () => reject("Error reading file");
      });
      const data = { image: base64data, filename: 'image.jpg' };
      return await this.doRequest('post', route, data);
    } catch (error) {
      console.error("Upload error:", error);
      throw error;
    }
  }

  //----------------------------------------------------------------------------
  // Return Request
  //----------------------------------------------------------------------------

  async returnRequest(data: any, method?: string, cache?: string): Promise<any> {
    let status = data?.status;
    let error = data?.data?.error;

    // Loading
    if (method == 'get') { this.stopLoading(); }
    setTimeout(() => { this.stopLoading(); }, 5000);

    // Errors
    if (status == 401) return this.userUnauthorized(data?.data?.message);
    if (error && status == 402) return this.maximumPremium(data?.data?.message);
    if (error && status == 409) return this.maximumInvites(data?.data?.message);
    if (error && status == 410) return this.updateApp();
    if (error && status == 426) return this.verifyAccount(data?.data?.message);

    // Success
    if (!data?.data?.error && (data?.status == 200 || data?.status == 201)) {
      if (data?.data.account && data?.data.account[0]) data.data.view = data?.data?.account[0];
      if (data?.data.view && data?.data.view[0]) data.data.view = data?.data?.view[0];
      if (cache) this.cache.storeCache(cache, data?.data);
      return data?.data;
    }

    // Error
    this.ionic.showToast(data?.data?.message);
    return false;
  }

  //----------------------------------------------------------------------------
  // 401: User Unauthorized
  //----------------------------------------------------------------------------

  async userUnauthorized(message: string) {
    this.firebase.setEvent('api_unauthorized');
    this.ionic.showToast(message);

    // Prompt
    const prompt = await this.ionic.showConfirm(
      message,
      this.translate.instant('UnauthorizedLogout')
    );
    if (prompt) {
      this.cache.clearCache();
      this.capacitor.clearStorage();
      this.router.navigateByUrl('/login');
    }
  }

  //----------------------------------------------------------------------------
  // 402: Maximum Premium
  //----------------------------------------------------------------------------

  async maximumPremium(message: string) {
    this.firebase.setEvent('api_premium');
    this.firebase.setEvent('premium_max');
    this.firebase.setEvent('premium_max_' + message);
    this.ionic.openPage(PremiumPage, message);
    return;
  }

  //----------------------------------------------------------------------------
  // 409: Maximum Invites
  //----------------------------------------------------------------------------

  async maximumInvites(message: string) {
    this.firebase.setEvent('api_invites');

    // Prompt
    const prompt = await this.ionic.showConfirm(
      this.translate.instant('Invites'),
      message
    );
    if (prompt) this.ionic.openPage(PremiumPage);
    return;
  }

  //----------------------------------------------------------------------------
  // 410: Update Application
  //----------------------------------------------------------------------------

  async updateApp() {
    this.firebase.setEvent('api_update');

    // Prompt
    const prompt = await this.ionic.showConfirm(
      this.translate.instant('AppUpdate'),
      this.translate.instant('AppUpdateMessage')
    );
    if (prompt) {
      if (await this.capacitor.getPlatform() == 'android') {
        await this.capacitor.openBrowser(linkGoogle);
      } else {
        await this.capacitor.openBrowser(linkApple);
      }
    }
    return;
  }

  //----------------------------------------------------------------------------
  // 426: Verify Account
  //----------------------------------------------------------------------------

  async verifyAccount(message: string) {
    this.firebase.setEvent('api_anonymous');
    this.firebase.setEvent('anonymous_max');
    this.firebase.setEvent('anonymous_max_' + message);
    this.ionic.openPage(LoginPage, 'verify');
    return;
  }

  //----------------------------------------------------------------------------
  // Stop Loading
  //----------------------------------------------------------------------------

  async stopLoading() {
    this.isLoading('');
    this.isRemoving('');
  }
}

