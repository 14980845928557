import { appNumber, crispChat, linkApple, linkGoogle, linkReview } from '../../../environments/environment';
import { Component, NgZone, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {
  CalendarPage,
  MediaPage,
  PremiumPage,
  SupportPage,
  LoginPage,
  TourPage,
  AssistantPage,
} from '..';
import {
  CapacitorService,
  FirebaseService,
  EventsService,
  CacheService,
  IonicService,
  HttpService,
  MapboxService
} from '../../services';

@Component({
  standalone: true,
  selector: 'page-account',
  templateUrl: './account.page.html',
  styleUrls: ['./account.page.scss'],
  imports: [
    IonicModule, CommonModule,
    ComponentsModule, TranslateModule
  ],
})
export class AccountPage implements OnInit {

  // ViewChild
  @ViewChild('upload', { read: ElementRef }) uploadFile!: ElementRef;

  // Variables
  data: any;
  appicon: string = 'default';
  version: string = appNumber;
  upload: boolean = false;
  invite: boolean = false;
  map: boolean = true;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public modalAccount: ModalController,
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public firebase: FirebaseService,
    public mapbox: MapboxService,
    public events: EventsService,
    public ionic: IonicService,
    public cache: CacheService,
    public http: HttpService,
    public router: Router,
    public zone: NgZone,
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  ngOnInit() {
    this.firebase.setScreen('Account');
    this.events.subscribeEvent('account', () => { this.getData(); });
    this.getCache();
  }

  ngOnDestroy() {
    this.events.destroyEvent('account');
  }

  //----------------------------------------------------------------------------
  // Get Cache
  //----------------------------------------------------------------------------

  async getCache() {
    this.zone.run(async () => {
      this.data = await this.cache.getCache('account');
      if(this.data) {
        this.data.view = this.data?.account[0];
        this.data.view.appearance = await this.capacitor.getAppearance();
      }
      this.getData();
    });
  }

  //----------------------------------------------------------------------------
  // Get Data
  //----------------------------------------------------------------------------

  async getData() {
    this.zone.run(async () => {
      this.data = await this.http.getRequest('/account', 'account');
      this.data.view = this.data.account[0];
      this.data.view.appearance = await this.capacitor.getAppearance();
      this.data.view.map = !await this.capacitor.getStorage('Map');
    });
  }

  //----------------------------------------------------------------------------
  // Edit Username
  //----------------------------------------------------------------------------

  async editUsername() {
    this.firebase.setEvent('account_username');

    // Verified
    if (!this.data?.view?.verified) return this.openLogin();

    // Prompt
    const prompt = await this.ionic.showPrompt(
      'text', this.translate.instant('Username'), '',
      this.data?.view?.username, this.translate.instant('Username')
    );

    // Update
    if (prompt) {
      await this.http.putRequest('/account', { username: prompt });
      this.getData();
    }
  }

  //----------------------------------------------------------------------------
  // Edit Name
  //----------------------------------------------------------------------------

  async editName() {
    this.firebase.setEvent('account_name');

    // Verified
    if (!this.data?.view?.verified) return this.openLogin();

    // Prompt
    const prompt = await this.ionic.showPrompt(
      'text', this.translate.instant('Name'), '',
      this.data?.view?.name, this.translate.instant('Name')
    );

    // Update
    if (prompt) {
      await this.http.putRequest('/account', { name: prompt });
      this.getData();
    }
  }

  //----------------------------------------------------------------------------
  // Edit Email
  //----------------------------------------------------------------------------

  async editEmail() {
    this.firebase.setEvent('account_email');

    // Verified
    if (!this.data?.view?.verified) return this.openLogin();

    // Prompt
    const prompt = await this.ionic.showPrompt(
      'email', this.translate.instant('Email'), '',
      this.data?.view?.email, this.translate.instant('Email')
    );

    // Update
    if (prompt) {
      await this.http.putRequest('/account', { email: prompt });
      this.getData();
    }
  }

  //----------------------------------------------------------------------------
  // Edit Avatar
  //----------------------------------------------------------------------------

  async editAvatar() {
    this.firebase.setEvent('account_avatar');

    // Verified
    if (!this.data?.view?.verified) return this.openLogin();

    // Photos
    this.capacitor.getPhotos().then(async (photo: any) => {
      this.upload = true;
      await this.http.uploadImage('/view/' + this.data?.view?.id + '/1', photo).then(data => {
        if(data) {
          this.data.avatar = data?.image;
          this.ionic.showAlert('Success', 'Avatar updated');
          this.getData();
        }
      });
      this.upload = false;
    });
  }

  //----------------------------------------------------------------------------
  // Edit Privacy
  //----------------------------------------------------------------------------

  async editPrivacy(data: boolean) {
    this.firebase.setEvent('account_privacy');

    // Verified
    if (!this.data?.view?.verified) return this.openLogin();

    // Update
    await this.http.putRequest('/account', { privacy: data });
    this.getData();
  }

  //----------------------------------------------------------------------------
  // Edit Notifications
  //----------------------------------------------------------------------------

  async editNotifications(data: boolean) {
    this.firebase.setEvent('account_push');

    // Update
    await this.ionic.setTimeout(50);
    await this.capacitor.registerNotifications();
    let token = await this.capacitor.getToken();
    await this.http.putRequest('/account', { push: token });
    this.getData();
  }

  //----------------------------------------------------------------------------
  // Edit Language
  //----------------------------------------------------------------------------

  async editLanguage(data: string) {
    this.firebase.setEvent('account_language');

    // Update
    this.capacitor.getLanguage(data);
    await this.capacitor.setStorage('Language', data);
    await this.http.putRequest('/account', { language: data });
    this.getData();
  }

  //----------------------------------------------------------------------------
  // Edit Appearance
  //----------------------------------------------------------------------------

  async editAppearance(data: string) {
    this.firebase.setEvent('account_appearance');
    this.firebase.setEvent('account_appearance_' + data);

    // Update
    let scheme = await this.capacitor.setAppearance(data);
    this.mapbox.setStyle(scheme);
  }

  //----------------------------------------------------------------------------
  // Edit Map
  //----------------------------------------------------------------------------

  async editMap(data: boolean) {
    this.firebase.setEvent('account_map');
    
    // Update
    if (!data) {
      await this.capacitor.setStorage('Map', 'disabled');
      this.capacitor.map = false;
      window.location.reload();
    } else {
      this.firebase.setEvent('account_map_enabled');
      await this.capacitor.removeStorage('Map');
      this.capacitor.map = true;
      window.location.reload();
    }
  }

  //----------------------------------------------------------------------------
  // Open Store
  //----------------------------------------------------------------------------

  async openStore() {
    this.firebase.setEvent('account_rate');

    // Browser
    if (await this.capacitor.getPlatform() == 'android') {
      this.firebase.setEvent('account_rate_android');
      await this.capacitor.openBrowser(linkGoogle);
    } else if (await this.capacitor.getPlatform() == 'ios') {
      this.firebase.setEvent('account_rate_ios');
      await this.capacitor.openBrowser(linkApple);
    } else {
      this.firebase.setEvent('account_rate_web');
      await this.capacitor.openBrowser(linkReview);
    }
  }

  //----------------------------------------------------------------------------
  // Open Profile
  //----------------------------------------------------------------------------

  async openProfile() {
    this.firebase.setEvent('account_profile');
    this.ionic.openView('user', this.data?.view?.id);
  }

  //----------------------------------------------------------------------------
  // Open Premium
  //----------------------------------------------------------------------------

  async openPremium() {
    this.firebase.setEvent('account_premium');
    this.ionic.openPage(PremiumPage, 'account');
  }

  //----------------------------------------------------------------------------
  // Open Assistant
  //----------------------------------------------------------------------------

  async openAssistant() {
    this.firebase.setEvent('account_assistant');
    this.ionic.openPage(AssistantPage, 'account');
  }

  //----------------------------------------------------------------------------
  // Open Login
  //----------------------------------------------------------------------------

  async openLogin() {
    this.firebase.setEvent('account_verify');
    this.ionic.openPage(LoginPage, 'verify');
  }

  //----------------------------------------------------------------------------
  // Open Calendar
  //----------------------------------------------------------------------------

  async openCalendar() {
    this.firebase.setEvent('account_calendar');
    this.ionic.openPage(CalendarPage, 'calendar');
  }

  //----------------------------------------------------------------------------
  // Open Tour
  //----------------------------------------------------------------------------

  async openTour() {
    this.firebase.setEvent('account_tour');
    this.ionic.openPage(TourPage, 'account');
  }

  //----------------------------------------------------------------------------
  // Open Support
  //----------------------------------------------------------------------------

  async openSupport() {
    this.firebase.setEvent('account_support');
    this.ionic.openPage(SupportPage, 'account');
  }

  //----------------------------------------------------------------------------
  // Open Media
  //----------------------------------------------------------------------------

  async openMedia() {
    this.firebase.setEvent('account_media');
    this.ionic.openPage(MediaPage, 'account');
  }

  //----------------------------------------------------------------------------
  // Open Help
  //----------------------------------------------------------------------------

  async openHelp() {
    this.firebase.setEvent('account_help');
    this.capacitor.openBrowser('https://ibucket.app/help');
  }

  //----------------------------------------------------------------------------
  // Open Updates
  //----------------------------------------------------------------------------

  async openUpdates() {
    this.firebase.setEvent('account_updates');
    this.capacitor.openBrowser('https://ibucket.app/whats-new');
  }

  //----------------------------------------------------------------------------
  // Open Privacy
  //----------------------------------------------------------------------------

  async openPrivacy() {
    this.firebase.setEvent('account_privacy');
    this.capacitor.openBrowser('https://ibucket.app/privacy');
  }

  //----------------------------------------------------------------------------
  // Open Terms
  //----------------------------------------------------------------------------

  async openTerms() {
    this.firebase.setEvent('account_terms');
    this.capacitor.openBrowser('https://ibucket.app/terms');
  }

  //----------------------------------------------------------------------------
  // Open Green
  //----------------------------------------------------------------------------

  async openGreen() {
    this.firebase.setEvent('account_green');
    this.capacitor.openBrowser('https://www.thegreenwebfoundation.org/green-web-check/?url=ibucket.app');
  }

  //----------------------------------------------------------------------------
  // Open Instagram
  //----------------------------------------------------------------------------

  async openInstagram() {
    this.firebase.setEvent('account_instagram');
    this.capacitor.openLauncher(
      'instagram://user?username=ibucket.app',
      'https://www.instagram.com/ibucket.app'
    );
  }

  //----------------------------------------------------------------------------
  // Open Threads
  //----------------------------------------------------------------------------

  async openThreads() {
    this.firebase.setEvent('account_threads');
    this.capacitor.openLauncher(
      'barcelona://user?username=ibucket.app',
      'https://www.threads.net/ibucket.app'
    );
  }

  //----------------------------------------------------------------------------
  // Share App
  //----------------------------------------------------------------------------

  async shareApp() {
    this.firebase.setEvent('account_share');

    // Share
    if (await this.capacitor.isNative()) {
      await this.capacitor.openShare(
        'https://ibucket.app', 'Achieve your bucket list goals and trips together with iBucket. Download our app:'
      );
    } else {
      await this.capacitor.setClipboard(
        'https://ibucket.app', 'Achieve your bucket list goals and trips together with iBucket. Download our app:'
      );
      await this.ionic.showAlert(
        this.translate.instant('Share'),
        this.translate.instant('Copied')
      );
    }
  }

  //----------------------------------------------------------------------------
  // Clear Cache
  //----------------------------------------------------------------------------

  async clearCache() {
    this.firebase.setEvent('account_cache');

    // Prompt
    const prompt = await this.ionic.showConfirm(
      this.translate.instant('Cache'),
      this.translate.instant('CacheConfirm')
    );

    // Remove
    if (prompt) {
      await this.cache.clearCache();
      const data = await this.http.deleteRequest('/account/cache');
      this.ionic.showAlert('Cache', data?.message);
    }
  }

  //----------------------------------------------------------------------------
  // Logout Account
  //----------------------------------------------------------------------------

  async logoutAccount() {
    this.firebase.setEvent('account_logout');

    // Request
    const data = await this.http.deleteRequest('/login');

    // Logout
    this.cache.clearCache();
    this.capacitor.clearStorage();
    this.router.navigateByUrl('/login');
    await this.modalAccount.dismiss();
  }

  //----------------------------------------------------------------------------
  // Delete Account
  //----------------------------------------------------------------------------

  async deleteAccount() {
    this.firebase.setEvent('account_delete');

    // Prompt
    const prompt = await this.ionic.showConfirm(
      this.translate.instant('AccountDelete'),
      this.translate.instant('AccountDeleteInfo')
    );

    // Delete
    if (prompt) {
      const confirm = await this.ionic.showPrompt(
        'text', this.translate.instant('AccountDelete'),
        this.translate.instant('AccountDeleteConfirm'), ''
      );

      // Confirmed
      if (confirm) {
        const data = await this.http.deleteRequest('/account?confirm=' + confirm);
        if (data?.deleted) {
          this.firebase.setEvent('account_deleted');
          this.ionic.showAlert('Success', data['message']);
          this.cache.clearCache();
          this.capacitor.clearStorage();
          this.router.navigateByUrl('/login');
        }
      }
    }
  }

  //----------------------------------------------------------------------------
  // Close View
  //----------------------------------------------------------------------------

  async closePage() {
    const modal = await this.modalAccount.getTop();
    if (modal) {
      modal.setCurrentBreakpoint(0);
    } else {
      await this.modalAccount.dismiss();
    }
    this.events.refreshPages();
  }

  //----------------------------------------------------------------------------
  // Refresh Screen
  //----------------------------------------------------------------------------

  async doRefresh(e: any) {
    this.firebase.setEvent('account_refresh');
    setTimeout(async () => {
      this.getData(); 
      e.target.complete();
    }, 600);
  }
}
