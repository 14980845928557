<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closePage()">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'Media' | translate }}</ion-title>
  </ion-toolbar>
  <ion-toolbar class="filters">
    <ion-segment [(ngModel)]="filter" mode="md" (ionChange)="changeFilter(filter)">
      <ion-segment-button value="images">
        <ion-label>{{ 'Images' | translate }}</ion-label>
      </ion-segment-button>
      <ion-segment-button value="files">
        <ion-label>{{ 'Files' | translate }}</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<ion-content>

  <!-- Images -->

  <ion-grid *ngIf="filter == 'images'">
    <ion-list class="images" *ngIf="data?.images">
      <div class="square {{image?.type}}" (click)="deleteImage(image?.id)" *ngFor="let image of data?.images">
        <ion-icon [src]="'/assets/icons/color/trash-03.svg'"></ion-icon>
        <ion-img [src]="image?.image" *ngIf="image?.image"></ion-img>
      </div>
    </ion-list>
    <ion-list class="images" *ngIf="!data?.images">
      <div class="square" *ngFor="let _ of [].constructor(21)">
        <ion-skeleton-text animated></ion-skeleton-text>
      </div>
    </ion-list>
    <ion-list *ngIf="data?.images && data?.images?.length == 0">
      <div class="error">{{ 'ImagesEmpty' | translate }}</div>
    </ion-list>
  </ion-grid>

  <!-- Files -->

  <ion-grid *ngIf="filter == 'files'">
    <ion-list *ngIf="data?.files && data?.files?.length == 0">
      <div class="error">{{ 'FilesEmpty' | translate }}</div>
    </ion-list>
  </ion-grid>

</ion-content>