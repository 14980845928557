<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button text="" [icon]="'/assets/icons/' + capacitor.scheme + '/' + 'arrow-left.svg'" defaultHref="/" *ngIf="token"></ion-back-button>
      <ion-button (click)="closePage()" *ngIf="!token">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'Invites' | translate }}</ion-title>
    <div class="ibucket" *ngIf="token"></div>
    <ion-buttons slot="primary" end>
      <ion-button (click)="openTour()">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/message-question-circle.svg'"></ion-icon>
        <ion-label>{{ 'Help' | translate }}</ion-label>
      </ion-button>
    </ion-buttons>
    <div class="ibucket" *ngIf="token"></div>
  </ion-toolbar>
</ion-header>

<ion-content>

  <!-- Invite -->

  <ion-grid *ngIf="!token">
    <ion-list class="padding">
      <div class="info">
        <div class="text">{{ 'InviteTour' | translate }}</div>
        <button class="cta medium transparent outline invites" (click)="openPremium()">
          <ion-icon [src]="'/assets/icons/dark/users-check.svg'"></ion-icon>
          <ion-spinner *ngIf="!data"></ion-spinner>
          <span *ngIf="data && !data?.premium">
            {{ data?.limits?.invites?.count }}/{{ data?.limits?.invites?.premium }}
            ({{ 'PremiumFree' | translate }})
          </span>
          <span *ngIf="data && data?.premium">
            {{ data?.limits?.invites?.count }}/∞
            ({{ 'Premium' | translate }})
          </span>
        </button>
        <button class="cta medium white invites" (click)="openPremium()" *ngIf="data && !data?.premium">
          {{ 'PremiumGo' | translate }}
        </button>
      </div>
      <div class="error" *ngIf="data && !data?.premium && data?.limits?.invites?.maximum">{{ 'InviteUpgrade' | translate }}</div>
    </ion-list>
  </ion-grid>

  <!-- Received -->

  <ion-grid *ngIf="!token && invites?.received?.length != 0">
    <app-header title="Received"></app-header>
    <ion-list>
      <ion-skeleton-text class="list" [animated]="true" *ngIf="!data"></ion-skeleton-text>
      <app-item [data]="invite" class="box" *ngFor="let invite of invites?.received"></app-item>
    </ion-list>
  </ion-grid>

  <!-- Lists -->

  <ion-grid *ngIf="!token">
    <app-header title="Lists" [tour]="true"></app-header>
    <ion-list>
      <ion-skeleton-text class="list" [animated]="true" *ngIf="!data"></ion-skeleton-text>
      <div class="empty" *ngIf="invites?.lists?.length == 0">{{ 'ListsEmpty' | translate }}</div>
      <app-item [data]="invite" class="box" *ngFor="let invite of invites?.lists"></app-item>
    </ion-list>
  </ion-grid>

  <!-- Pending -->

  <ion-grid *ngIf="!token && invites?.pending?.length != 0">
    <app-header title="Pending"></app-header>
    <ion-list>
      <ion-skeleton-text class="list" [animated]="true" *ngIf="!data"></ion-skeleton-text>
      <app-item [data]="invite" *ngFor="let invite of invites?.pending"></app-item>
    </ion-list>
  </ion-grid>

  <!-- Accepted -->

  <ion-grid *ngIf="!token && invites?.accepted?.length != 0">
    <app-header title="Accepted"></app-header>
    <ion-list>
      <ion-skeleton-text class="list" [animated]="true" *ngIf="!data"></ion-skeleton-text>
      <app-item [data]="invite" *ngFor="let invite of invites?.accepted"></app-item>
    </ion-list>
  </ion-grid>

  <!-- Invited -->

  <ion-grid *ngIf="token">
    <ion-list class="padding">
      <div class="accept">
        <img src="/assets/svg/invite-visual.svg" width="200" height="200" class="visual" />
        <h1>You're invited to collaborate!</h1>
        <div class="text">
          You've received an invitation to collaborate on iBucket. Automatically accept the invite directly on your preferred device:
        </div>
        <div class="buttons">
          <a ion-button href="ibucket://invite/{{token}}" class="cta big primary" *ngIf="web">
            Open app
          </a>
          <a ion-button class="cta big primary" [class.secondary]="web" (click)="acceptInvite()">
            Accept on web
          </a>
        </div>
        <a href="https://ibucket.app" target="_blank" class="about">
          What is iBucket?
        </a>
      </div>
    </ion-list>
    <ion-list>
      <div class="accept">
        <div class="buttons" *ngIf="web && token">
          <div class="text">
            <strong>Or download our app:</strong>
          </div>
          <a href="https://itunes.apple.com/app/id864052715" title="App Store" target="_blank" rel="noopener" *ngIf="platform.is('ios')">
            <img src="/assets/svg/download-apple.svg" width="149" height="44" alt="Download App Store">
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.kaimallie.ibucket" title="Google Play" target="_blank" rel="noopener" *ngIf="platform.is('android')">
            <img src="/assets/svg/download-google.svg" width="149" height="44" alt="Download Google Play">
          </a>
        </div>
        <div class="buttons" *ngIf="!web && token">
          <h2>Accept in our app? Scan the QR:</h2>
          <div class="qrcode">
            <img src="https://api.qrserver.com/v1/create-qr-code/?size=400x400&color=181F36&data=ibucket://invite/{{token}}" class="qr" width="190" height="190" alt="Scan QR">
            <span>Open</span>
          </div>
          <div class="qrcode">
            <img src="https://api.qrserver.com/v1/create-qr-code/?size=400x400&color=181F36&data=https://ibucket.app/invite/{{token}}" class="qr" width="190" height="190" alt="Scan QR">
            <span>Download</span>
          </div>
        </div>
      </div>
    </ion-list>
  </ion-grid>
  
</ion-content>