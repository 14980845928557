import { pagePlace, pageMax } from '../../../environments/environment';
import { Component, OnInit, NgZone, ViewChild, ChangeDetectorRef } from '@angular/core';
import { IonicModule, InfiniteScrollCustomEvent, IonContent, ModalController } from '@ionic/angular';
import { ReactiveFormsModule, FormsModule, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { debounceTime } from "rxjs/operators";
import { PremiumPage } from '..';
import {
  CapacitorService,
  FirebaseService,
  EventsService,
  MapboxService,
  IonicService,
  HttpService,
} from '../../services';

@Component({
  standalone: true,
  selector: 'page-place',
  templateUrl: './place.page.html',
  styleUrls: ['./place.page.scss'],
  imports: [
    IonicModule, CommonModule, FormsModule,
    ReactiveFormsModule, ComponentsModule, TranslateModule
  ],
})
export class PlacePage implements OnInit {

  @ViewChild(IonContent, { static: false }) content!: IonContent;

  public loading$ = this.http.loading.asObservable();
  public pagePlace = pagePlace;
  public pageMax = pageMax;

  data: any;
  cities: any = [];
  id: string = '';
  count: number = 0;
  query: string = '';
  type: string = 'regions';
  expanded: boolean = false;
  search: FormControl = new FormControl();

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public modalPlace: ModalController,
    public capacitor: CapacitorService,
    public translate: TranslateService,
    public firebase: FirebaseService,
    public ref: ChangeDetectorRef,
    public mapbox: MapboxService,
    public events: EventsService,
    public ionic: IonicService,
    public http: HttpService,
    public zone: NgZone
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  ngOnInit() {
    this.firebase.setScreen('Place');
    this.events.subscribeEvent('place', () => { this.getData(); });
    this.initSearch();
  }

  ionViewDidEnter() {
    this.getData();
  }

  ngOnDestroy() {
    this.events.destroyEvent('place');
  }

  //----------------------------------------------------------------------------
  // Get Data
  //----------------------------------------------------------------------------

  async getData() {
    this.zone.run(async () => {
      const search = this.query ? '?q=' + this.query : '';
      this.data = await this.http.getRequest('/view/' + this.id + search, this.id);
      this.ref.detectChanges();
      this.infiniteScroll();
    });
  }

  //----------------------------------------------------------------------------
  // Switch Toggle
  //----------------------------------------------------------------------------

  async switchToggle(type: string = this.type) {
    this.firebase.setEvent('places_' + type);
    this.mapbox.modalHeight(1);
    this.type = type;
  }

  //----------------------------------------------------------------------------
  // Init Search
  //----------------------------------------------------------------------------

  async initSearch() {
    this.search.valueChanges.pipe(debounceTime(300)).subscribe(s => {
      this.content.scrollToTop(0);
      this.getData();
    });
  }

  //----------------------------------------------------------------------------
  // Add Place
  //----------------------------------------------------------------------------

  async addPlace(event: Event) {
    this.firebase.setEvent('places_add');
    this.events.cancelEvent(event);
    await this.mapbox.addMap(this.id);
    this.data.view.status = 'added';
  }

  //----------------------------------------------------------------------------
  // Complete Place
  //----------------------------------------------------------------------------

  async completePlace(event: Event) {
    this.firebase.setEvent('places_complete');
    this.events.cancelEvent(event);
    await this.mapbox.completeMap(this.id);
    this.data.view.status = 'completed';
  }

  //----------------------------------------------------------------------------
  // Delete Place
  //----------------------------------------------------------------------------

  async deletePlace(event: Event) {
    this.firebase.setEvent('places_delete');
    this.events.cancelEvent(event);
    await this.mapbox.deleteMap(this.id);
    this.data.view.status = '';
  }

  //----------------------------------------------------------------------------
  // Expand Views
  //----------------------------------------------------------------------------

  async expandView() {
    this.firebase.setEvent('place_expand');
    this.mapbox.modalPlaceBreakpoint();
  }

  //----------------------------------------------------------------------------
  // Open Premium
  //----------------------------------------------------------------------------
  
  async openPremium() {
    this.firebase.setEvent('place_premium');
    this.ionic.openPage(PremiumPage, 'premium');
  }

  //----------------------------------------------------------------------------
  // Infinite Scroll
  //----------------------------------------------------------------------------

  private infiniteScroll() {
    this.zone.run(async () => {
      this.count = 0;
      this.cities = [];
      if(this.data?.cities && this.data?.cities.length >= 200) {
        for (this.count = 0; this.count < 20; this.count++) {
          this.cities.push(this.data?.cities[this.count]);
        }
      } else {
        this.cities = this.data?.cities;
      }
      this.ref.detectChanges();
    });
  }

  //----------------------------------------------------------------------------
  // Infinite Scroll
  //----------------------------------------------------------------------------

  async doInfinite(ev: Event) {
    let extra = this.count + 10;
    if (this.data?.cities.length >= extra) {
      for (this.count = this.count; this.count < extra; this.count++) {
        this.cities.push(this.data?.cities[this.count]);
      }
    } else {
      (ev as InfiniteScrollCustomEvent).target.disabled = true;
    }
    (ev as InfiniteScrollCustomEvent).target.complete()
  }
}
