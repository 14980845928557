import { Component, OnInit, NgZone } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components';
import { Router } from '@angular/router';
import {
  CapacitorService,
  FirebaseService,
  IonicService,
  HttpService,
} from '../../services';

@Component({
  standalone: true,
  selector: 'page-tour',
  templateUrl: './tour.page.html',
  styleUrls: ['./tour.page.scss'],
  imports: [
    IonicModule, CommonModule,
    ComponentsModule, TranslateModule
  ],
})
export class TourPage implements OnInit {

  data: any = [];
  tour: any = [];
  type!: string;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public capacitor: CapacitorService,
    public translate: TranslateService,
    public firebase: FirebaseService,
    public modalTour: ModalController,
    public ionic: IonicService,
    public http: HttpService,
    public router: Router,
    public zone: NgZone
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  ngOnInit() {
    this.firebase.setScreen('Tour');
    this.getData();
  }

  //----------------------------------------------------------------------------
  // Get Data
  //----------------------------------------------------------------------------

  async getData() {
    this.getGoals();
    this.getLists();
    this.getTasks();
    this.getPlaces();
    this.getFriends();
  }

  //----------------------------------------------------------------------------
  // Get Goals
  //----------------------------------------------------------------------------

  getGoals() {
    this.tour.goals = [{
      'articles': [
        {
          'icon': 'link-external-01',
          'title': 'How to define goals?',
          'url': ''
        },
        {
          'icon': 'link-external-01',
          'title': 'How to achieve goals?',
          'url': ''
        },
      ],
      'guide': [
        {
          'icon': 'circle',
          'title': 'Define and write down your goals'
        },
        {
          'icon': 'circle',
          'title': 'Create smaller tasks to breakdown goals'
        },
        {
          'icon': 'circle',
          'title': 'Create a list to organise multiple goals'
        },
        {
          'icon': 'circle',
          'title': 'Create a trip to plan multiple goals'
        },
        {
          'icon': 'circle',
          'title': 'Set reminders to stay motivated'
        },
        {
          'icon': 'circle',
          'title': 'Complete tasks to track progress'
        },
        {
          'icon': 'circle',
          'title': 'Add photos to visualise your success'
        },
      ],
      'legenda': [
        {
          'icon': 'arrow-square-left',
          'title': 'Swipe left delete a goal'
        },
        {
          'icon': 'arrow-square-right',
          'title': 'Swipe right to complete a goal'
        },
        {
          'icon': 'menu-05',
          'title': 'Hold and drag to move a goal'
        },
        {
          'icon': 'dots-vertical',
          'title': 'Press to view quick actions'
        },
      ],
      'example': {
        'type': 'goal',
        'title': 'Swim with Doplhins',
        'subtitle': 'Australia',
        'status': 'completed',
        'edit': 'true',
        'image': '/assets/imgs/tour-goal.jpg'
      }
    }];
  }

  //----------------------------------------------------------------------------
  // Get Lists
  //----------------------------------------------------------------------------

  getLists() {
    this.tour.lists = [{
      'articles': [
        {
          'icon': 'link-external-01',
          'title': 'How to plan a trip?',
          'url': ''
        },
        {
          'icon': 'link-external-01',
          'title': 'How to organise goals?',
          'url': ''
        },
      ],
      'guide': [
        {
          'icon': 'circle',
          'title': 'Create a list to organize goals'
        },
        {
          'icon': 'circle',
          'title': 'Track progress by completing goals'
        },
        {
          'icon': 'circle',
          'title': 'Create a trip for a day-by-day planner'
        },
        {
          'icon': 'circle',
          'title': 'Prepare your trip for best experience'
        },
      ],
      'legenda': [
        {
          'icon': 'stars-02',
          'title': 'Generate items by AI Assistant'
        },
        {
          'icon': 'plus-circle',
          'title': 'Add a new item to the list'
        },
        {
          'icon': 'grid-01',
          'title': 'Change display of the items'
        },
        {
          'icon': 'lock-unlocked-01',
          'title': 'Lock/unlock the position of all items'
        },
        {
          'icon': 'filter-lines',
          'title': 'Sort items in the list'
        },
        {
          'icon': 'dots-vertical',
          'title': 'Press to view quick actions'
        },
      ],
      'example': {
        'type': 'trip',
        'title': 'Roadtrip United States',
        'subtitle': 'September 2024',
        'status': 'completed',
        'label': 'Completed',
        'edit': true,
        'image': '/assets/imgs/tour-list.jpg'
      }
    }];
  }

  //----------------------------------------------------------------------------
  // Get Tasks
  //----------------------------------------------------------------------------

  getTasks() {
    this.tour.tasks = [{
      'articles': [
        {
          'icon': 'link-external-01',
          'title': 'How to set tasks?',
          'url': ''
        },
        {
          'icon': 'link-external-01',
          'title': 'How to complete tasks?',
          'url': ''
        },
      ],
      'guide': [
        {
          'icon': 'circle',
          'title': 'Define and write down your tasks'
        },
        {
          'icon': 'circle',
          'title': 'Rank tasks by importance and urgency'
        },
        {
          'icon': 'circle',
          'title': 'Focus on high-impact tasks first.'
        },
        {
          'icon': 'circle',
          'title': 'Assign a realistic deadline to each task'
        },
        {
          'icon': 'circle',
          'title': 'Use a calendar to track deadlines'
        },
        {
          'icon': 'circle',
          'title': 'Identify necessary resources'
        },
        {
          'icon': 'circle',
          'title': 'Start with the highest priority tasks'
        },
        {
          'icon': 'circle',
          'title': 'Reward yourself for completing tasks'
        },
      ],
      'legenda': [
        {
          'icon': 'check-square',
          'title': 'Check to complete a task'
        },
        {
          'icon': 'arrow-square-left',
          'title': 'Swipe left delete a task'
        },
        {
          'icon': 'arrow-square-right',
          'title': 'Swipe right to complete a task'
        },
        {
          'icon': 'menu-05',
          'title': 'Hold and drag to move a task'
        },
        {
          'icon': 'dots-vertical',
          'title': 'Press to view quick actions'
        },
      ],
      'example': {
        'type': 'task',
        'title': 'Buy airplane tickets',
        'subtitle': '2024-07-22',
        'status': 'completed',
        'time': '12:00',
        'edit': true
      }
    }];
  }

  //----------------------------------------------------------------------------
  // Get Places
  //----------------------------------------------------------------------------

  getPlaces() {
    this.tour.places = [{
      'articles': [
        {
          'icon': 'link-external-01',
          'title': 'How to track places?',
          'url': ''
        },
        {
          'icon': 'link-external-01',
          'title': 'How to plan a trip?',
          'url': ''
        },
      ],
      'guide': [
        {
          'icon': 'circle',
          'title': 'Mark countries you\'ve visited'
        },
        {
          'icon': 'circle',
          'title': 'Mark regions you\'ve visited'
        },
        {
          'icon': 'circle',
          'title': 'Mark cities you\'ve visited'
        },
        {
          'icon': 'circle',
          'title': 'Add places to a goals and tasks'
        },
      ],
      'legenda': [
        {
          'icon': 'map-01',
          'title': 'Press a country to mark places'
        },
        {
          'icon': 'heart-rounded',
          'title': 'Press to mark place as visited'
        },
        {
          'icon': 'flag-02',
          'title': 'Press to mark place as want to visited'
        },
      ],
      'example': {
        'type': 'place',
        'title': 'Amsterdam',
        'subtitle': 'Noord-Holland, Netherlands',
        'status': 'completed',
        'display': 'map'
      }
    }];
  }

  //----------------------------------------------------------------------------
  // Get Friends
  //----------------------------------------------------------------------------

  getFriends() {
    this.tour.friends = [{
      'articles': [
        {
          'icon': 'link-external-01',
          'title': 'How to collaborate?',
          'url': ''
        },
        {
          'icon': 'link-external-01',
          'title': 'How to invite people?',
          'url': ''
        },
      ],
      'guide': [
        {
          'icon': 'circle',
          'title': 'Invite people to work together'
        },
        {
          'icon': 'circle',
          'title': 'Follow friends to track progress'
        },
        {
          'icon': 'circle',
          'title': 'Accept/deny follower requests'
        },
        {
          'icon': 'circle',
          'title': 'Accept/deny received invitations'
        },
      ],
      'legenda': [
        {
          'icon': 'user-plus-01',
          'title': 'Press to invite a user'
        },
        {
          'icon': 'user-check-01',
          'title': 'Press to follow a user'
        },
        {
          'icon': 'user-x-01',
          'title': 'Press to unfollow a user'
        },
        {
          'icon': 'check-circle',
          'title': 'Press to accept request'
        },
        {
          'icon': 'x-circle',
          'title': 'Press to deny request'
        },
      ],
      'example': {
        'type': 'user',
        'title': 'Kimberly Hicks',
        'subtitle': 'Received invitation',
        'status': 'received',
        'image': '/assets/imgs/tour-friends.jpg'
      }
    }];
  }

  //----------------------------------------------------------------------------
  // Close View
  //----------------------------------------------------------------------------

  async closePage() {
    const modal = await this.modalTour.getTop();
    if (modal) {
      modal.setCurrentBreakpoint(0);
    } else {
      await this.modalTour.dismiss();
    }
  }
}
