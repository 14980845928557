import { Component, OnInit, NgZone } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../../components';
import {
  CapacitorService,
  FirebaseService,
  IonicService,
  HttpService
} from '../../services';

@Component({
  standalone: true,
  selector: 'page-media',
  templateUrl: './media.page.html',
  styleUrls: ['./media.page.scss'],
  imports: [
    IonicModule, CommonModule, FormsModule,
    ComponentsModule, TranslateModule
  ],
})
export class MediaPage implements OnInit {

  // Variables
  data: any;
  filter: string = 'images';

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public modalMedia: ModalController,
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public firebase: FirebaseService,
    public ionic: IonicService,
    public http: HttpService,
    public zone: NgZone
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  ngOnInit() {
    this.firebase.setScreen('Media');
    this.getData();
  }

  //----------------------------------------------------------------------------
  // Get Data
  //----------------------------------------------------------------------------

  async getData() {
    this.zone.run(async () => {
      this.data = await this.http.getRequest('/media');
    });
  }

  //----------------------------------------------------------------------------
  // Change Filter
  //----------------------------------------------------------------------------
  
  async changeFilter(data: any) {
    this.firebase.setEvent('media_filter');
    this.filter = data;
  }

  //----------------------------------------------------------------------------
  // Open Image
  //----------------------------------------------------------------------------

  async openImage(id: string) {
    this.firebase.setEvent('media_image');
  }

  //----------------------------------------------------------------------------
  // Delete Image
  //----------------------------------------------------------------------------

  async deleteImage(id: string) {
    const prompt = await this.ionic.showConfirm(
      this.translate.instant('Delete'),
      this.translate.instant('DeleteConfirm')
    );
    if (prompt) {
      this.firebase.setEvent('media_image_delete');
      await this.http.deleteRequest('/media/' + id);
      this.data = null;
      this.getData();
    }
  }

  //----------------------------------------------------------------------------
  // Open File
  //----------------------------------------------------------------------------

  async openFile(id: string) {
    this.firebase.setEvent('media_file');
  }

  //----------------------------------------------------------------------------
  // Delete File
  //----------------------------------------------------------------------------

  async deleteFile(id: string) {
    this.firebase.setEvent('media_file_delete');
  }

  //----------------------------------------------------------------------------
  // Close View
  //----------------------------------------------------------------------------

  async closePage() {
    const modal = await this.modalMedia.getTop();
    if (modal) {
      modal.setCurrentBreakpoint(0);
    } else {
      await this.modalMedia.dismiss();
    }
  }
}
