<ion-card class="card {{data?.type}} {{display}}" [class.img]="data?.image" (click)="openCard()" *ngIf="(removing$ | async) != data?.id">

    <div class="button-add" (click)="copyCard($event)">
      <ion-icon src="/assets/icons/color/plus.svg"></ion-icon>
    </div>

    <cached-image [src]="data?.image" [cache]="cache" width="100%" height="100%" *ngIf="data?.image"></cached-image>

    <div class="label {{data?.status}} {{display}}" *ngIf="data?.label">
      <img [src]="data?.avatar" width="26" height="26" *ngIf="data?.avatar && display == 'friends'">
      <span>{{ data?.label | translate }}</span>
    </div>

    <div class="button edit" *ngIf="data?.edit" (click)="editCard($event)">
      <ion-icon src="/assets/icons/color/dots-vertical.svg" *ngIf="!data?.image"></ion-icon> 
      <ion-icon src="/assets/icons/dark/dots-vertical.svg" *ngIf="data?.image"></ion-icon>
    </div>

    <div class="button invited" *ngIf="data?.invited">
      <ion-icon src="/assets/icons/light/users-03.svg" *ngIf="!data?.image"></ion-icon> 
      <ion-icon src="/assets/icons/dark/users-03.svg" *ngIf="data?.image"></ion-icon>
    </div>

    <div class="inside" [class.image]="data?.image">
      <div class="bottom">
        <div class="subtitle ion-text-wrap">{{ data?.subtitle }}</div>
        <div class="title ion-text-wrap">{{ data?.title }}</div>
        <div class="progress" *ngIf="data?.progress || data?.progress == 0">
          <div class="line" [ngStyle]="{'width': data?.progress + '%'}"></div>
        </div>
      </div>
    </div>
    
  </ion-card>

  <!-- Item Remove -->

  <ion-card class="card loading" disabled *ngIf="(removing$ | async) == data?.id">
    <ion-spinner name="circular"></ion-spinner>
  </ion-card>