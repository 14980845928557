import { Component, ViewChild, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { IonicModule, ModalController, IonDatetime, IonModal } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../../components';
import { InvitePage, AssistantPage, PremiumPage } from '..';
import { SearchPage } from '..';
import {
  CapacitorService,
  FirebaseService,
  EventsService,
  IonicService,
  HttpService,
  GoalsService
} from '../../services';

@Component({
  standalone: true,
  selector: 'page-manage',
  templateUrl: './manage.page.html',
  styleUrls: ['./manage.page.scss'],
  imports: [
    IonicModule, CommonModule, FormsModule,
    ComponentsModule, TranslateModule
  ],
})
export class ManagePage implements OnInit {

  // ViewChild
  @ViewChild(IonModal) datemodal!: IonModal;
  @ViewChild(IonDatetime) datetime!: IonDatetime;
  @ViewChild('input') headerTitle: any;

  // Parameters
  data: any = [];
  view: any = [];
  limits: any = [];
  id: string = '';
  type: string = 'goal';
  function: string = 'add'; 
  loaded: boolean = false;
  upload: string = '0';
  disable: boolean = false;
  maxData: any = (new Date()).getFullYear() + 10;
  dateType: string = '';
  dateSelected: string = new Date().toISOString();

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public modalManage: ModalController,
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public firebase: FirebaseService,
    public ref: ChangeDetectorRef,
    public events: EventsService,
    public ionic: IonicService,
    public goals: GoalsService,
    public http: HttpService,
    public zone: NgZone,
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  async ngOnInit() {
    this.firebase.setScreen('Manage');
    this.events.subscribeEvent('manage', (data) => { this.view[data] = '' });
    this.getData();
  }

  async ngOnDestroy() {
    this.events.destroyEvent('manage');
  }

  //----------------------------------------------------------------------------
  // Get Data
  //----------------------------------------------------------------------------

  async getData() {
    this.zone.run(() => {
      if (this.id && this.id != '0') {
        this.editItem();
      } else {
        this.addItem();
      }
    });
  }

  //----------------------------------------------------------------------------
  // Add Item
  //----------------------------------------------------------------------------

  async addItem() {
    this.view = this.data;

    // Remove
    if (this.view && !this.view?.edit && this.view?.type != 'listheader') {
      delete this.view.bucket;
      delete this.view.bucketTitle;
      delete this.view.trip;
      delete this.view.tripTitle;
    }
    if (this.view && this.type == 'task') {
      delete this.view.refer;
      delete this.view.place;
      delete this.view.placeTitle;
      delete this.view.planned;
      delete this.view.finished;
      delete this.view.reminder;
    }

    // Setup
    const params = this.view;
    this.view = {};
    this.view.status = false;
    this.view.owner = true;
    this.view.id = params ? params.id : '';
    this.view.title = params && !params.edit ? params.title : '';
    this.view.planned = params ? params.planned : '';
    this.view.trip = params ? params.trip : '';
    this.view.tripTitle = params ? params.tripTitle : '';
    this.view.bucket = params ? params.bucket : '';
    this.view.bucketTitle = params ? params.bucketTitle : '';
    this.view.place = params ? params.refer : '';
    this.view.placeTitle = params ? params.placeTitle : '';
    this.view.goals = params && params.goals ? params.goals : [];
    this.view.images = [];
    
    // Config
    this.http.getRequest('/view').then((data: any) => {
      this.limits = data;
    });

    // Loaded
    this.loaded = true;
    setTimeout(() => {
      this.headerTitle.setFocus();
    }, 700);
  }

  //----------------------------------------------------------------------------
  // Edit Item
  //----------------------------------------------------------------------------

  async editItem() {
    this.firebase.setEvent('view_' + this.type);
    this.http.getRequest('/view/' + this.id).then((data: any) => {
      this.data = data;
      this.view = this.data?.view;
      this.view.place = this.view?.refer;
      this.view.status = this.view?.status == 'completed' ? true : false;
      this.view.goals = [];
      this.loaded = true;
    });
  }

  //----------------------------------------------------------------------------
  // Close View
  //----------------------------------------------------------------------------

  async closeView() {

    // Close
    if (this.view?.title || this.view?.story) {
      await this.ionic.showConfirm(
        this.translate.instant('Close'),
        this.translate.instant('CloseInfo')
      ).then((data: any) => {
        if (data) {
          setTimeout(() => {
            this.closePage();
          }, 200);
        }
      });
    } else {
      setTimeout(() => {
        this.closePage();
      }, 200);
    }
  }

  //----------------------------------------------------------------------------
  // Save
  //----------------------------------------------------------------------------

  async saveItem() {

    // Disable
    this.disable = true;
    setTimeout(() => { this.disable = false; }, 5000);

    // Request
    let request: any;
    if (this.id) {
      request = await this.http.putRequest('/view/' + this.id, this.view);
    } else {
      request = await this.http.postRequest('/view/' + this.type, this.view);
    }

    // Return
    if (request) {
      this.firebase.setEvent('view_added_' + this.type);
      this.capacitor.requestReview(request?.reviews);
      this.setReminder();
      this.closePage();
    }
    this.disable = false;
  }

  //----------------------------------------------------------------------------
  // Delete Data
  //----------------------------------------------------------------------------

  async deleteData(type: string, data1: string, data2: string) {
    this.firebase.setEvent('view_delete');

    // Clear
    if (data1) this.view[data1] = '';
    if (data2) this.view[data2] = '';
  }

  //----------------------------------------------------------------------------
  // Open Date
  //----------------------------------------------------------------------------

  async openDate(key: string, date: any = null) {
    if(key == 'reminder' && !this.capacitor.premium) {
      this.firebase.setEvent('reminder_premium');
      this.ionic.openPage(PremiumPage, 'premium');
      return;
    }
    this.dateType = key;
    const datetime = date ? date : new Date().toISOString().slice(0, 10);
    this.dateSelected = this.ionic.formatDate(datetime);
    console.log(date, this.dateSelected);
    this.datemodal.present();
  }

  //----------------------------------------------------------------------------
  // Save Date
  //----------------------------------------------------------------------------

  async saveDate() {
    this.firebase.setEvent('view_' + this.dateType);
    await this.datetime.confirm(true);
    this.view[this.dateType] = this.dateSelected;
    this.dateSelected = '';
  }

  //----------------------------------------------------------------------------
  // Set Date
  //----------------------------------------------------------------------------

  async setDate(event: CustomEvent) {
    this.dateSelected = event.detail.value;
  }

  //----------------------------------------------------------------------------
  // Current Date
  //----------------------------------------------------------------------------

  async currentDate() {
    this.dateSelected = new Date().toISOString();
  }

  //----------------------------------------------------------------------------
  // Clear Date
  //----------------------------------------------------------------------------

  async clearDate() {
    await this.datetime.reset();
    this.dateSelected = '';
  }

  //----------------------------------------------------------------------------
  // Clear Time
  //----------------------------------------------------------------------------

  async clearTime() {
    await this.datetime.confirm(false);
    this.dateSelected = this.dateSelected.split('T')[0] + 'T00:00:00';
  }

  //----------------------------------------------------------------------------
  // Cancel Date
  //----------------------------------------------------------------------------

  async cancelDate() {
    this.datetime.cancel(true);
    this.dateSelected = '';
  }

  //----------------------------------------------------------------------------
  // Open Invite
  //----------------------------------------------------------------------------

  async openInvite() {
    this.firebase.setEvent('view_invite');

    // Modal
    if (this.id) {
      await this.ionic.openPage(InvitePage, this.type, this.id).then((json: any) => {
        if (json) {
          this.view.people = json?.invites;
        }
      });
    } else {
      await this.ionic.showAlert(
        this.translate.instant('Error'),
        this.translate.instant('InvitePublish')
      );
    }
  }

  //----------------------------------------------------------------------------
  // Open Assistant
  //----------------------------------------------------------------------------

  async openAssistant() {
    this.firebase.setEvent('view_assistant');

    // Modal
    if (this.id) {
      await this.ionic.openPage(AssistantPage, this.type, this.id);
    } else {
      await this.ionic.showAlert(
        this.translate.instant('Error'),
        this.translate.instant('AssistantPublish')
      );
    }
  }

  //----------------------------------------------------------------------------
  // Open Premium
  //----------------------------------------------------------------------------

  async openPremium() {
    this.firebase.setEvent('manage_premium');
    this.ionic.openPage(PremiumPage, 'premium');
  }

  //----------------------------------------------------------------------------
  // Set Status
  //----------------------------------------------------------------------------

  async setStatus() {
    this.firebase.setEvent('view_status');
    this.view.status = !this.view?.status;

    // Completed
    if (this.view?.status && !this.view?.completed) {
      let current = new Date();
      const day = current.getDate() < 10 ? '0' + current.getDate() : current.getDate();
      const month = (current.getMonth() + 1) < 10 ? '0' + (current.getMonth() + 1) : current.getMonth() + 1;
      this.view.completed = current.getFullYear() + '-' + month + '-' + day;
    }
  }

  //----------------------------------------------------------------------------
  // Set Status
  //----------------------------------------------------------------------------

  async setAutocomplete() {
    this.firebase.setEvent('view_autocomplete');
    this.view.autocomplete = !this.view?.autocomplete;
  }

  //----------------------------------------------------------------------------
  // Set Reminder
  //----------------------------------------------------------------------------

  async setReminder() {
    if (this.view?.reminder) {
      this.firebase.setEvent('view_reminder');
      let type = this.type == 'task' ? this.translate.instant('Task') : this.translate.instant('Goal');
      this.capacitor.localNotification(this.view?.reminder, type + ' Reminder', this.view?.title);
    }
  }

  //----------------------------------------------------------------------------
  // Set Tickets
  //----------------------------------------------------------------------------

  async setTickets() {
    this.firebase.setEvent('view_tickets');
    this.view.tickets = !this.view?.tickets;
  }

  //----------------------------------------------------------------------------
  // Set Budget
  //----------------------------------------------------------------------------

  async setBudget() {
    await this.ionic.showPrompt(
      'number', this.translate.instant('Budget'),
      this.translate.instant('BudgetInfo'), this.view?.budget, '0'
    ).then(data => {
      if (data) {
        this.firebase.setEvent('view_budget');
        this.view.budget = data;
      }
    });
  }

  //----------------------------------------------------------------------------
  // Set Reservation
  //----------------------------------------------------------------------------

  async setReservation() {
    await this.ionic.showPrompt(
      'text', this.translate.instant('Reservation'),
      this.translate.instant('ReservationInfo') + ' ' + this.translate.instant('ReservationPrivacy'),
      this.view?.reservation, '#'
    ).then(data => {
      if (data) {
        this.firebase.setEvent('view_reservation');
        this.view.reservation = data;
      }
    });
  }

  //----------------------------------------------------------------------------
  // Set Website
  //----------------------------------------------------------------------------

  async setWebsite() {
    await this.ionic.showPrompt(
      'url', this.translate.instant('Website'),
      this.translate.instant('WebsiteNote'), this.view?.website, 'https://'
    ).then(data => {
      if (data) {
        this.firebase.setEvent('view_website');
        this.view.website = data;
      }
    });
  }

  //----------------------------------------------------------------------------
  // Set Video
  //----------------------------------------------------------------------------

  async setVideo() {
    await this.ionic.showPrompt(
      'url', this.translate.instant('Video'),
      this.translate.instant('VideoNote'), this.view?.video, 'https://youtube.com/watch?v='
    ).then(data => {
      if (data) {
        this.firebase.setEvent('view_video');
        this.view.video = data;
      }
    });
  }

  //----------------------------------------------------------------------------
  // Set Lists
  //----------------------------------------------------------------------------

  async setLists() {
    await this.ionic.openPage(SearchPage, 'lists').then(json => {
      if (json && json.type == 'bucket') {
        this.firebase.setEvent('view_list');
        this.view.bucket = json.id;
        this.view.bucketTitle = json.title;
      }
      if (json && json.type == 'trip') {
        this.firebase.setEvent('view_trip');
        this.view.trip = json.id;
        this.view.tripTitle = json.title;
      }
    });
  }

  //----------------------------------------------------------------------------
  // Set Category
  //----------------------------------------------------------------------------

  async setCategory() {
    await this.ionic.openPage(SearchPage, 'categories').then(json => {
      if (json) {
        this.firebase.setEvent('view_category');
        this.view.category = json.id;
        this.view.categoryTitle = json.title;
      }
    });
  }

  //----------------------------------------------------------------------------
  // Set Activity
  //----------------------------------------------------------------------------

  async setActivity() {
    await this.ionic.openPage(SearchPage, 'activities').then(json => {
      if (json) {
        this.firebase.setEvent('view_activity');
        this.view.activity = json.id;
        this.view.activityTitle = json.title;
      }
    });
  }

  //----------------------------------------------------------------------------
  // Set Place
  //----------------------------------------------------------------------------

  async setPlace(type: string) {
    await this.ionic.openPage(SearchPage, 'places').then(json => {
      if (json && type == 'place') {
        this.firebase.setEvent('view_place');
        this.view.place = json.refer;
        this.view.placeTitle = json.title;
      }
      if (json && type == 'placestart') {
        this.firebase.setEvent('view_placestart');
        this.view.startPlace = json.refer;
        this.view.startTitle = json.title;
      }
      if (json && type == 'placeend') {
        this.firebase.setEvent('view_placeend');
        this.view.endPlace = json.refer;
        this.view.endTitle = json.title;
      }
    });
  }

  //----------------------------------------------------------------------------
  // Set Image
  //----------------------------------------------------------------------------

  async setImage(position: string) {
    this.firebase.setEvent('view_image');

    // Options
    let array = [];
    let title = this.translate.instant('Image');
    array.push({
      text: this.translate.instant('Search'),
      cssClass: 'action-sheet-icon search',
      handler: () => {
        this.firebase.setEvent('view_image_unsplash');
        this.imageUnsplash(position);
      }
    });
    array.push({
      text: this.translate.instant('Library'),
      cssClass: 'action-sheet-icon library',
      handler: () => {
        this.capacitor.getPhotos().then((image: any) => {
          this.firebase.setEvent('view_image_library');
          this.imageUpload(image, position);
        });
      }
    });
    return await this.ionic.showSheet(title, array);
  }

  //----------------------------------------------------------------------------
  // Image Unsplash
  //----------------------------------------------------------------------------

  async imageUnsplash(position: string) {
    await this.ionic.openPage(SearchPage, 'images').then(async json => {
      if (json) {
        this.upload = position;
        let id = this.id ? this.id : await this.ionic.encryptID(this.type, '0');
        this.http.postRequest('/media/' + id + '/' + position, {
          imageID: json?.id,
          imageURL: json?.image
        }).then(data => {
          if (data) {
            this.upload = '0';
            this.view['image' + position] = data['file'];
            this.view.images['image' + position] = data['image'];
          }
        });
      }
    });
  }

  //----------------------------------------------------------------------------
  // Image Upload
  //----------------------------------------------------------------------------

  async imageUpload(photo: any, position: string) {
    this.upload = position;
    let id = this.id ? this.id : await this.ionic.encryptID(this.type, '0');
    return await this.http.uploadImage('/media/' + id + '/' + position, photo).then(data => {
      if(data) {
        this.view['image' + position] = data['file'];
        this.view.images['image' + position] = data['image'];
      }
      this.upload = '0';
    });
  }

  //----------------------------------------------------------------------------
  // Delete Goal
  //----------------------------------------------------------------------------

  async deleteGoal(index: number) {
    this.zone.run(() => {
      this.firebase.setEvent('view_delete');
      this.view.goals.splice(index, 1);
      this.ref.detectChanges();
    });
  }

  //----------------------------------------------------------------------------
  // Delete Item
  //----------------------------------------------------------------------------

  async deleteItem() {
    this.firebase.setEvent('view_delete');
    this.events.cancelEvent();
    await this.goals.deleteItem(this.id);
    this.closePage()
  }

  //----------------------------------------------------------------------------
  // Delete Image
  //----------------------------------------------------------------------------

  async deleteImage(position: string) {
    const prompt = await this.ionic.showConfirm(
      this.translate.instant('Delete'),
      this.translate.instant('DeleteConfirm')
    );
    if (prompt) {
      this.firebase.setEvent('view_image_delete');
      this.view['image' + position] = '';
      this.view.images['image' + position] = '';
      this.http.deleteRequest('/view/' + this.id + '/' + position);
    }
  }

  //----------------------------------------------------------------------------
  // Close View
  //----------------------------------------------------------------------------

  async closePage() {
    const modal = await this.modalManage.getTop();
    if (modal) {
      modal.setCurrentBreakpoint(0);
    } else {
      await this.modalManage.dismiss();
    }
    this.events.refreshPages();
  }
}
