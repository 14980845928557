import { inject } from '@angular/core';
import { CapacitorService } from './services';
import { Routes } from '@angular/router';

export const routes: Routes = [

  // Root

  {
    path: '',
    canMatch: [() => inject(CapacitorService).checkRoute(true)],
    loadComponent: () => import('./pages/map/map.page').then(m => m.MapPage),
  },
  {
    path: '',
    canMatch: [() => inject(CapacitorService).checkRoute(false)],
    loadComponent: () => import('./pages/home/home.page').then(m => m.HomePage),
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  
  // Parameters
  
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage)
  },
  {
    path: 'login/:id',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage)
  },
  {
    path: 'invite/:id',
    loadComponent: () => import('./pages/invite/invite.page').then(m => m.InvitePage)
  },
  {
    path: 'view/:id',
    loadComponent: () => import('./pages/view/view.page').then(m => m.ViewPage)
  },

  // Pages
  
  {
    path: 'premium',
    loadComponent: () => import('./pages/premium/premium.page').then(m => m.PremiumPage)
  },

  // Redirects

  {
    path: '**',
    redirectTo: '/',
  }
];