<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closePage()">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'Passport' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding sides list">
  <ion-grid class="medium">
    <div class="soon">
      <img src="/assets/svg/passport-visual.svg"/>
      <div class="title"> {{ 'Passport' | translate }}</div>
      <div class="text"> {{ 'PassportSoon' | translate }}</div>
      <div class="statistics"> {{ 'MapProgress' | translate: { percent: data?.map?.progress } }}</div>
      <button class="cta medium secondary" (click)="closePage()">
        {{ 'Close' | translate }}
      </button>
    </div>
  </ion-grid>
</ion-content>
