import { Component, OnInit, NgZone, ChangeDetectorRef, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components';
import {
  PurchaseService,
  CapacitorService,
  FirebaseService,
  HttpService,
  EventsService,
  IonicService
} from '../../services';

@Component({
  standalone: true,
  selector: 'page-premium',
  templateUrl: './premium.page.html',
  styleUrls: ['./premium.page.scss'],
  imports: [
    IonicModule, CommonModule,
    ComponentsModule, TranslateModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class PremiumPage implements OnInit {

  data: any;
  packages: boolean = false;
  spinner: boolean = true;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public modalPremium: ModalController,
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public purchase: PurchaseService,
    public firebase: FirebaseService,
    public ref: ChangeDetectorRef,
    public events: EventsService,
    public ionic: IonicService,
    public http: HttpService,
    public zone: NgZone
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  ngOnInit() {
    this.firebase.setScreen('Premium');
    this.events.subscribeEvent('premium', () => { this.getData(); });
    this.getData();
  }

  ngOnDestroy() {
    this.events.destroyEvent('premium');
  }

  //----------------------------------------------------------------------------
  // Get Data
  //----------------------------------------------------------------------------

  async getData() {
    this.zone.run(async () => {
      this.data = await this.http.getRequest('/premium');
      this.initPurchase();
    });
  }

  //----------------------------------------------------------------------------
  // Init Purchase
  //----------------------------------------------------------------------------

  async initPurchase() {
    this.firebase.setEvent('premium_initialize');

    // Init
    this.zone.run(async () => {
      if (await this.capacitor.isNative()) {
        const offerings = await this.purchase.initPurchase(this.data?.account[0]?.purchase);
        this.data.packages.premium.price = offerings?.all[this.data.packages?.premium?.refer]?.lifetime?.product?.priceString;
        this.data.packages.goals.price = offerings?.all[this.data.packages?.goals?.refer]?.lifetime?.product?.priceString;
        this.data.packages.places.price = offerings?.all[this.data.packages?.places?.refer]?.lifetime?.product?.priceString;
      }
      this.ref.detectChanges();
      this.spinner = false;
    });
  }

  //----------------------------------------------------------------------------
  // Purchase Package
  //----------------------------------------------------------------------------

  async purchasePackage(refer: string) {
    this.firebase.setEvent('premium_purchase');
    this.firebase.setEvent('premium_purchase_' + refer);

    // Purchase
    let offer = this.data?.packages?.[refer];
    if(!offer?.purchased && !offer?.locked) {
      this.spinner = true;
      this.purchase.purchasePackage(offer?.refer, offer?.stripe);
    }
  }

  //----------------------------------------------------------------------------
  // Show Packages
  //----------------------------------------------------------------------------

  async showPackages(event: Event) {
    this.firebase.setEvent('premium_packages');
    this.packages = true;
    event.stopPropagation();
    event.preventDefault();
  }

  //----------------------------------------------------------------------------
  // Purchase Restore
  //----------------------------------------------------------------------------

  async restorePackage() {
    this.firebase.setEvent('premium_restore');
    this.purchase.restorePackage();
  }

  //----------------------------------------------------------------------------
  // Open Reviews
  //----------------------------------------------------------------------------

  async openReviews() {
    this.firebase.setEvent('premium_reviews');
    this.capacitor.openBrowser('https://ibucket.app/reviews/');
  }

  //----------------------------------------------------------------------------
  // Close Modal
  //----------------------------------------------------------------------------

  async closeModal() {
    this.packages = false;
  }

  //----------------------------------------------------------------------------
  // Close View
  //----------------------------------------------------------------------------

  async closePage() {
    const modal = await this.modalPremium.getTop();
    if (modal) {
      modal.setCurrentBreakpoint(0);
    } else {
      await this.modalPremium.dismiss();
    }
    this.events.refreshPages();
  }
}