<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closePage()">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-input #autofocus [formControl]="control" [(ngModel)]="search" [clearInput]="true" placeholder="{{ 'Search' | translate }}"></ion-input>
  </ion-toolbar>
  <ion-toolbar class="filters" *ngIf="data && data?.filters && data?.filters.length != 0">
    <ion-segment [(ngModel)]="filter" mode="md" (ionChange)="changeFilter(filter)">
      <ion-segment-button value="">
        <ion-label>{{ 'Places' | translate }}</ion-label>
      </ion-segment-button>
      <ion-segment-button value="community">
        <ion-label>{{ 'Goals' | translate }}</ion-label>
      </ion-segment-button>
      <ion-segment-button value="users">
        <ion-label>{{ 'Users' | translate }}</ion-label>
      </ion-segment-button>
      <ion-segment-button value="bucketlist">
        <ion-label>{{ 'Me' | translate }}</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <!-- Ideas -->

  <ion-grid *ngIf="!search && filter == 'community'">
    <ion-list class="community" *ngIf="ideas?.results">
      <div class="square {{result?.type}}" (click)="openIdea(result)" *ngFor="let result of ideas?.results">
        <ion-img [src]="result?.image" *ngIf="result?.image"></ion-img>
        <ion-skeleton-text animated></ion-skeleton-text>
      </div>
    </ion-list>
    <ion-list class="community" *ngIf="!ideas?.results">
      <div class="square" *ngFor="let _ of [].constructor(17)">
        <ion-skeleton-text animated></ion-skeleton-text>
      </div>
    </ion-list>
  </ion-grid>
  
  <!-- Search -->

  <ion-grid *ngIf="filter != 'community' || search">
    <app-header title="Results" [spinner]="loader"></app-header>
    <div class="start" *ngIf="data && filter == 'images' && !search">
      {{ 'SearchStart' | translate }}
    </div>
    <div class="add" *ngIf="data && filter == 'places'" (click)="addCustom()">
      <ion-icon [src]="'/assets/icons/blue/plus.svg'"></ion-icon>
      {{ 'PlaceCustom' | translate }}
    </div>
    <ion-list>
      <app-item [data]="result" *ngFor="let result of data?.results"></app-item>
    </ion-list>
  </ion-grid>

  <!-- Suggest -->

  <ion-grid *ngIf="filter == 'map' && search">
    <ion-list>
      <button ion-button class="cta medium mediumgrey outline" (click)="suggestMap()">
        {{ 'SuggestCity' | translate }}
      </button>
    </ion-list>
  </ion-grid>

</ion-content>