import { appNumber } from '../../../environments/environment';
import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../../components';
import { LoginPage } from '..';
import { Router } from '@angular/router';
import {
  CapacitorService,
  FirebaseService,
  IonicService,
  HttpService
} from '../../services';

@Component({
  standalone: true,
  selector: 'page-support',
  templateUrl: './support.page.html',
  styleUrls: ['./support.page.scss'],
  imports: [
    IonicModule, CommonModule, FormsModule,
    ComponentsModule, TranslateModule
  ],
})
export class SupportPage implements OnInit {

  @ViewChild('input') textField: any;

  // Variables
  data: any;
  help: any;
  id: string = '';
  type: string = '';
  email: string = '';
  debug: string = '';
  message: string = '';
  disable: boolean = false;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public modalSupport: ModalController,
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public firebase: FirebaseService,
    public ionic: IonicService,
    public http: HttpService,
    public router: Router,
    public zone: NgZone
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  ngOnInit() {
    this.firebase.setScreen('Support');
    this.getData();
    this.setDebug();
  }

  //----------------------------------------------------------------------------
  // Get Data
  //----------------------------------------------------------------------------

  async getData() {
    this.zone.run(async () => {
      this.data = await this.http.getRequest('/account');
      this.email = this.data?.account[0]?.email ?? '';
    });
  }

  //----------------------------------------------------------------------------
  // Set Debug
  //----------------------------------------------------------------------------

  async setDebug() {
    let info = await this.capacitor.getInfo();
    this.debug = 'version ' + appNumber + ' • ' + info.platform + ' ' + info.osVersion + ' • ' + info.model;
    return this.debug;
  }

  //----------------------------------------------------------------------------
  // Open Login
  //----------------------------------------------------------------------------

  async openLogin() {
    this.firebase.setEvent('account_verify');
    this.ionic.openPage(LoginPage, 'verify');
  }

  //----------------------------------------------------------------------------
  // Open Help
  //----------------------------------------------------------------------------

  async openHelp() {
    this.firebase.setEvent('account_help');
    this.capacitor.openBrowser('https://ibucket.app/help');
  }

  //----------------------------------------------------------------------------
  // Send Message
  //----------------------------------------------------------------------------

  async sendMessage() {
    this.firebase.setEvent('support_message');

    // Request
    this.disable = true;
    await this.http.postRequest('/account/support', {
      message: this.message,
      email: this.email,
      debug: this.debug
    }).then(data => {
      if (data) {
        this.ionic.showAlert(this.translate.instant('Support'), data?.message);
        this.message = '';
      }
    });
    this.disable = false;
  }

  //----------------------------------------------------------------------------
  // Close View
  //----------------------------------------------------------------------------

  async closePage() {
    const modal = await this.modalSupport.getTop();
    if (modal) {
      modal.setCurrentBreakpoint(0);
      await this.ionic.setTimeout(500);
      await this.modalSupport.dismiss();
    }
  }
}