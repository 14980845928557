import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

import { HttpClient, provideHttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Capacitor } from '@capacitor/core';

let locale: string;
const platform = Capacitor.getPlatform();

// Translation
export function createTranslateLoader(http: HttpClient) {
  if(platform == 'web') locale = '.';
  if(platform == 'ios') locale = 'capacitor://localhost';
  if(platform == 'android') locale = 'https://localhost';
  return new TranslateHttpLoader(http, locale + '/assets/locale/', '.json');
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [

    // Strategy
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    importProvidersFrom(IonicModule.forRoot({})),

    // Translate
    provideHttpClient(),
    importProvidersFrom(TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })),

    // ServiceWorker
    importProvidersFrom(ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })),

    // Routes
    provideRouter(routes),
  ],
});