import { Component, OnInit, NgZone } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components';
import { Router } from '@angular/router';
import {
  CapacitorService,
  FirebaseService,
  EventsService,
  HttpService,
  IonicService,
} from '../../services';

@Component({
  standalone: true,
  selector: 'page-passport',
  templateUrl: './passport.page.html',
  styleUrls: ['./passport.page.scss'],
  imports: [
    IonicModule, CommonModule,
    ComponentsModule, TranslateModule
  ],
})
export class PassportPage implements OnInit {
  
  data: any;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public translate: TranslateService,
    public firebase: FirebaseService,
    public capacitor: CapacitorService,
    public modalPassport: ModalController,
    public events: EventsService,
    public ionic: IonicService,
    public http: HttpService,
    public router: Router,
    public zone: NgZone
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  async ngOnInit() {
    this.firebase.setScreen('Passport');
    this.getData();
  }

  //----------------------------------------------------------------------------
  // Get Data
  //----------------------------------------------------------------------------

  async getData() {
    this.zone.run(async () => {
      this.data = await this.http.getRequest('/me/passport');
    });
  }

  //----------------------------------------------------------------------------
  // Close View
  //----------------------------------------------------------------------------

  async closePage() {
    const modal = await this.modalPassport.getTop();
    if (modal) {
      modal.setCurrentBreakpoint(0);
    } else {
      await this.modalPassport.dismiss();
    }
  }
}
