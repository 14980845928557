export const environment = {
  production: true
};

// Configuration
export const appDebug = false;
export const appNumber = '11.4.0';
export const appServer = 'https://api.ibucket.app';
export const appSecurity = 'ZSM7DHAa4eZy36HAbbWj57e9774B3jNk';
export const appSync = 5000;

// Links
export const linkApple = 'https://apps.apple.com/app/id864052715?action=write-review';
export const linkGoogle = 'https://play.google.com/store/apps/details?id=com.kaimallie.ibucket';
export const linkReview = 'https://ibucket.app/reviews/write/';
export const linkTickets = 'https://www.getyourguide.com/s/?partner_id=ZDQFSGD&utm_medium=online_publisher&q=';
export const linkStays = 'https://www.booking.com/searchresults.html?aid=1222247';
export const linkFlights = 'https://www.booking.com/flights/index.nl.html?aid=1222247';
export const linkRentals = 'https://www.booking.com/cars/index.nl.html?aid=1222247';

// Social
export const socialAppleClient = 'com.kaimallie.ibucket.web';
export const socialAppleRedirect = 'https://web.ibucket.app';
export const socialGoogleWeb = '611406298035-0jis6eojbnv622hfkfoslskfu9gg20u5.apps.googleusercontent.com';
export const socialGoogleiOS = '611406298035-24d5morep6irekpj5lu7a29u285i9qe8.apps.googleusercontent.com';
export const socialGoogleServer = '611406298035-0jis6eojbnv622hfkfoslskfu9gg20u5.apps.googleusercontent.com';

// Crisp
export const crispChat = 'https://go.crisp.chat/chat/embed/?website_id=79349623-1210-43e8-9850-689a8e378a6c';

// Mapbox
export const mapboxKey = 'pk.eyJ1Ijoia2FpbWFsbGllIiwiYSI6ImNqa2lhbHZ1eDB6emIzcGp5Y3NpdWNqbmIifQ.jXGwMMs1XEmrwE4N3_kiMg';
export const mapboxLight = 'mapbox://styles/kaimallie/clc372o9p000715peddl9cdno';
export const mapboxDark = 'mapbox://styles/kaimallie/cldq7q54a006r01qq4mwjva48';

// Premium
export const purchaseApple = 'appl_lLILHWCJeJOoDdPCkZnYYpbpUvj';
export const purchaseGoogle = 'goog_LDMSzZqChKtUwkIJVWxoMfRtcEF';
export const purchaseStripe = 'strp_qfHTRPrvNaiDOTMAYNtUxBLPcYS';

// Breakpoints
export const pageMin = 0;
export const pageMap = .3;
export const pagePlace = .4;
export const pageStart = .5;
export const pageBackdrop = .8;
export const pageMax = 1;
