import { Component, ViewChild, OnInit, NgZone } from '@angular/core';
import { IonicModule, ModalController, IonContent, IonSearchbar, AlertController } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components';
import { debounceTime } from "rxjs/operators";
import { Router } from '@angular/router';
import { ViewPage } from '../view/view.page';
import {
  CapacitorService,
  FirebaseService,
  EventsService,
  MapboxService,
  IonicService,
  CacheService,
  HttpService
} from '../../services';

@Component({
  standalone: true,
  selector: 'page-search',
  templateUrl: './search.page.html',
  styleUrls: ['./search.page.scss'],
  imports: [
    IonicModule, CommonModule, FormsModule,
    ReactiveFormsModule, ComponentsModule, TranslateModule
  ],
})
export class SearchPage implements OnInit {

  @ViewChild(IonContent, { static: false }) content!: IonContent;
  @ViewChild('autofocus', { static: false }) searchBar!: IonSearchbar;

  data: any = [];
  ideas: any = [];
  id: string = '';
  type: string = '';
  search: string = '';
  loader: boolean = false;
  empty: boolean = false;
  control: FormControl = new FormControl();
  filter: string = '';

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public modalSearch: ModalController,
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public firebase: FirebaseService,
    public alerts: AlertController,
    public events: EventsService,
    public places: MapboxService,
    public ionic: IonicService,
    public cache: CacheService,
    public http: HttpService,
    public router: Router,
    public zone: NgZone,
  ) {}

  //----------------------------------------------------------------------------
  // Analytics
  //----------------------------------------------------------------------------

  ngOnInit() {
    this.firebase.setScreen('Search');
    this.events.subscribeEvent('search', () => { this.getData(); });
    this.filter = this.filter;
    this.filter = this.type ? this.type : this.filter ? this.filter : '';
    this.initSearch();
    this.getData();
  }

  ngOnDestroy() {
    this.events.destroyEvent('search');
  }

  //----------------------------------------------------------------------------
  // Get Data
  //----------------------------------------------------------------------------

  async getData() {
    let event = this.filter ? this.filter : 'ideas';
    this.firebase.setEvent('search_' + event);
    this.zone.run(async () => {
      const url = '/me/search?q=' + this.search + '&f=' + this.filter;
      this.data = await this.http.getRequest(url);
      this.ideas = this.data;
      this.content.scrollToTop(0);
      setTimeout(() => {
        this.searchBar.setFocus();
      }, 700);
      this.loader = false;
    });
  }

  //----------------------------------------------------------------------------
  // Change Filter
  //----------------------------------------------------------------------------

  async changeFilter(data: any) {
    this.firebase.setEvent('search_filter');
    this.filter = data;
    this.data.results = null;
    this.loader = true;
    this.getData();
  }

  //----------------------------------------------------------------------------
  // Open Idea
  //----------------------------------------------------------------------------

  async openIdea(data?: any) {
    this.firebase.setEvent('search_idea');
    this.ionic.openView(data?.type, data?.id);
  }

  //----------------------------------------------------------------------------
  // Init Search
  //----------------------------------------------------------------------------

  async initSearch() {
    this.control.valueChanges.pipe(debounceTime(600)).subscribe(search => {
      this.zone.run(async () => {
        this.loader = true;
        await this.ionic.setTimeout(50);
        this.firebase.setEvent('search_query');
        this.getData();
      });
    });
  }

  //----------------------------------------------------------------------------
  // Suggest Map
  //----------------------------------------------------------------------------

  async suggestMap() {
    this.firebase.setEvent('places_suggest');
    this.places.suggestMap(this.data?.view?.iso);
  }

  //----------------------------------------------------------------------------
  // Add Custom
  //----------------------------------------------------------------------------

  async addCustom() {
    this.firebase.setEvent('places_custom');

    // Alert
    const alert = await this.alerts.create({
      header: this.translate.instant('Place'),
      message: this.translate.instant('PlaceAdd'),
      inputs: [{
        name: 'title',
        type: 'text',
        placeholder: this.translate.instant('Title')
      },
      {
        name: 'latitude',
        type: 'number',
        placeholder: 'Latitude (52.377956)'
      },
      {
        name: 'longitude',
        type: 'number',
        placeholder: 'Longitude (4.897070)'
      }],
      mode: 'ios',
      buttons: [{
        text: this.translate.instant('Cancel'),
        role: 'cancel'
      }, {
        text: this.translate.instant('Add'),
        role: 'add'
      }]
    });

    // Return
    await alert.present();
    const result = await alert.onDidDismiss();
    if(result.role == 'add') {
      await this.http.postRequest('/view/place', {
        title: result.data.values.title,
        latitude: result.data.values.latitude,
        longitude: result.data.values.longitude
      }).then(data => {
        if (data) {
          console.log('Success', data);
          this.modalSearch.dismiss({
            'refer': data?.id,
            'title': result.data.values.title,
          });
        }
      });
    };
  }

  //----------------------------------------------------------------------------
  // Close View
  //----------------------------------------------------------------------------

  async closePage() {
    const modal = await this.modalSearch.getTop();
    if (modal) {
      modal.setCurrentBreakpoint(0);
    } else {
      await this.modalSearch.dismiss();
    }
    this.events.refreshPages();
  }

  //----------------------------------------------------------------------------
  // Refresh Screen
  //----------------------------------------------------------------------------

  async doRefresh(e: any) {
    this.firebase.setEvent('search_refresh');
    this.zone.run(async () => {
      setTimeout(async () => {
        this.content.scrollToTop(0);
        this.data = await this.http.getRequest('/me/search?refresh=true', 'ideas');
        e.target.complete();
      }, 600);
    });
  }
}
