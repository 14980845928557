import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  AssistantPage,
  ManagePage,
  SearchPage
} from '../../pages';
import {
  CapacitorService,
  FirebaseService,
  SortingService,
  IonicService
} from '../../services';

@Component({
  standalone: false,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  // Variables
  @Input() data: any = [];
  @Input() id: any;
  @Input() title: any;
  @Input() type: any;
  @Input() items: any;
  @Input() hide: any;
  @Input() sorting: any;
  @Input() add: string = '';
  @Input() generating: boolean = false;
  @Input() edit: boolean = false;
  @Input() tour: boolean = false;
  @Input() spinner: boolean = false;
  @Input() class: string = '';

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public firebase: FirebaseService,
    public sort: SortingService,
    public ionic: IonicService
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  async ngOnInit() { }

  //----------------------------------------------------------------------------
  // Open Assistant
  //----------------------------------------------------------------------------

  async openAssistant() {
    this.firebase.setEvent('view_assistant');
    this.ionic.openPage(AssistantPage, this.type, this.id);
  }

  //----------------------------------------------------------------------------
  // Open Friends
  //----------------------------------------------------------------------------

  async openFriends() {
    this.firebase.setEvent('account_friends');
    this.ionic.openPage(SearchPage, 'friends');
  }
  //----------------------------------------------------------------------------
  // Open Sort
  //----------------------------------------------------------------------------

  async openSort() {
    this.firebase.setEvent('view_sort');
    this.sort.sortList(this.type, this.sorting, this.id);
  }

  //----------------------------------------------------------------------------
  // Open Manage
  //----------------------------------------------------------------------------

  async openManage() {
    this.firebase.setEvent('view_add');
    this.ionic.openPage(ManagePage, this.type, '', this.data);
  }

  //----------------------------------------------------------------------------
  // Lock Order
  //----------------------------------------------------------------------------

  async lockOrder() {
    this.firebase.setEvent('view_lock');
    this.sort.lockOrder(false, this.type);
    this.capacitor.lock = true;
  }

  //----------------------------------------------------------------------------
  // Unlock Order
  //----------------------------------------------------------------------------

  async unlockOrder() {
    this.firebase.setEvent('view_unlock');
    this.sort.lockOrder(true, this.type);
    this.capacitor.lock = false;
  }

  //----------------------------------------------------------------------------
  // Switch Display
  //----------------------------------------------------------------------------

  async switchDisplay(display: boolean) {
    this.firebase.setEvent('view_display');
    this.capacitor.display = display;
    this.sort.changeDisplay(this.capacitor.display, this.type);
  }
}
