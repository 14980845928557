import { appDebug, purchaseApple, purchaseGoogle, purchaseStripe } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Purchases, PurchasesPackage, LOG_LEVEL } from '@revenuecat/purchases-capacitor';
import {
  CapacitorService,
  FirebaseService,
  EventsService,
  IonicService,
  HttpService,
} from '..';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  key: string = '';
  public offerings: any = [];
  public active: string = 'premium';
  public package: any = [];
  public user: string = '';

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public firebase: FirebaseService,
    public events: EventsService,
    public ionic: IonicService,
    public http: HttpService
  ) { }

  //----------------------------------------------------------------------------
  // Init Purchase
  //----------------------------------------------------------------------------

  async initPurchase(user: string) {
    
    // Platform
    let platform = await this.capacitor.getPlatform();
    if (platform == 'android') this.key = purchaseGoogle;
    if (platform == 'ios') this.key = purchaseApple;
    if (platform == 'web') this.key = purchaseStripe;

    // Debug
    if(appDebug) await Purchases.setMockWebResults({
      shouldMockWebResults: true
    });
    if(appDebug) await Purchases.setLogLevel({ 
      level: LOG_LEVEL.VERBOSE
    });

    // Configure
    await Purchases.configure({
      apiKey: this.key,
      appUserID: user
    });

    // Return
    return await this.getOfferings();
  }

  //----------------------------------------------------------------------------
  // Get Offerings
  //----------------------------------------------------------------------------

  async getOfferings() {
    this.offerings = await Purchases.getOfferings();
    return this.offerings;
  }

  //----------------------------------------------------------------------------
  // Purchase Package
  //----------------------------------------------------------------------------

  async purchasePackage(refer: string, stripe: string) {
    if (await this.capacitor.isNative()) {
      this.purchaseRevenueCat(this.offerings?.all[refer]?.lifetime);
    } else {
      this.purchaseStripe(stripe);
    }
  }

  //----------------------------------------------------------------------------
  // Purchase RevenueCat
  //----------------------------------------------------------------------------

  async purchaseRevenueCat(p: PurchasesPackage) {
    await Purchases.purchasePackage({
      aPackage: p
    }).then(async purchase => {
      this.capacitor.premium = true;
      this.firebase.setEvent('premium_purchased');
      await this.purchaseActivate();
      this.events.publishEvent('assistant');
      this.events.publishEvent('premium');
      this.events.publishEvent('account');
      this.events.refreshPages();
      return true;
    }).catch(async error => {
      this.events.publishEvent('assistant');
      this.events.publishEvent('premium');
      this.events.publishEvent('account');
      this.events.refreshPages();
      return false;
    });
  }

  //----------------------------------------------------------------------------
  // Purchase Stripe
  //----------------------------------------------------------------------------

  async purchaseStripe(url: string) {
    this.firebase.setEvent('premium_stripe');
    this.capacitor.openBrowser(url);
    this.events.publishEvent('assistant');
    this.events.publishEvent('premium');
    this.events.publishEvent('account');
    this.events.refreshPages();
  }

  //----------------------------------------------------------------------------
  // Purchase Activate
  //----------------------------------------------------------------------------

  async purchaseActivate() {
    this.firebase.setEvent('premium_activate');
    this.http.postRequest('/premium').then(data => {
      if (data) {
        this.firebase.setEvent('premium_activated');
        this.ionic.showAlert(
          this.translate.instant('Premium'),
          data['message']
        );
        this.events.publishEvent('assistant');
        this.events.publishEvent('premium');
        this.events.publishEvent('account');
        this.events.refreshPages();
        return true;
      }
      return false;
    });
  }

  //----------------------------------------------------------------------------
  // Restore Purchase
  //----------------------------------------------------------------------------

  async restorePackage() {
    if (await this.capacitor.isNative()) {
      await Purchases.restorePurchases();
      await this.purchaseActivate();
      this.events.publishEvent('assistant');
      this.events.publishEvent('premium');
      this.events.publishEvent('account');
      this.events.refreshPages();
    }
  }
}
