import { Component, OnInit, NgZone } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components';
import { SearchPage, LoginPage } from '..';
import {
  FirebaseService,
  EventsService,
  HttpService,
  IonicService,
  CapacitorService,
  PurchaseService
} from '../../services';

@Component({
  standalone: true,
  selector: 'page-assistant',
  templateUrl: './assistant.page.html',
  styleUrls: ['./assistant.page.scss'],
  imports: [
    IonicModule, CommonModule, 
    ComponentsModule, TranslateModule
  ],
})
export class AssistantPage implements OnInit {

  data: any;
  id: string = '';
  type: string = '';
  result: any = [];
  loaded: boolean = false;
  generating: boolean = false;
  purchasing: boolean = false;
  completed: boolean = false;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public modalAssistant: ModalController,
    public translate: TranslateService,
    public capacitor: CapacitorService,
    public firebase: FirebaseService,
    public purchase: PurchaseService,
    public events: EventsService,
    public ionic: IonicService,
    public http: HttpService,
    public zone: NgZone
  ) { }

  //----------------------------------------------------------------------------
  // Initialize
  //----------------------------------------------------------------------------

  async ngOnInit() {
    this.firebase.setScreen('Assistant');
    this.events.subscribeEvent('assistant', () => {
      this.getData();
    });
    this.getData();
  }

  ngOnDestroy() {
    this.events.destroyEvent('assistant');
  }

  //----------------------------------------------------------------------------
  // Get Data
  //----------------------------------------------------------------------------

  async getData() {
    this.zone.run(async () => {
      this.data = await this.http.getRequest('/premium/' + this.id);
      this.generating = this.data?.view?.assistant;
      this.purchasing = false;
      await this.purchase.initPurchase(this.data?.view?.purchase);
    });
  }

  //----------------------------------------------------------------------------
  // Purchase Tokens
  //----------------------------------------------------------------------------

  async purchaseTokens() {
    this.firebase.setEvent('assistant_purchase');
    //this.purchase.purchasePackage(this.data?.packages[0]);
    this.purchasing = true;
  }

  //----------------------------------------------------------------------------
  // Open Login
  //----------------------------------------------------------------------------

  async openLogin() {
    this.firebase.setEvent('assistant_verify');
    this.ionic.openPage(LoginPage, 'verify');
  }

  //----------------------------------------------------------------------------
  // Generate Items
  //----------------------------------------------------------------------------

  async generateItems(type: string) {
    this.firebase.setEvent('assistant_generate');
    this.generating = true;

    // Request
    this.zone.run(async () => {
      return await this.http.postRequest('/premium/' + this.id, {
        title: this.data?.view?.title,
        category: this.data?.view?.categoryTitle,
        activity: this.data?.view?.activity,
        place: this.data?.view?.placeTitle,
        startDate: this.data?.view?.startDate,
        endDate: this.data?.view?.endDate
      }).then(async data => {
        if(data) {
          this.firebase.setEvent('assistant_generated');
          this.data.tokens = this.data?.tokens - 1;
          this.result = data?.created;
          this.generating = false;
          this.completed = true;
        } else {
          this.generating = false;
        }
      })
      .catch(error => {
         this.generating = false;
      });
    });
  }

  //----------------------------------------------------------------------------
  // Set Title
  //----------------------------------------------------------------------------

  async setTitle() {
    this.firebase.setEvent('assistant_title');

    // Prompt
    await this.ionic.showPrompt(
      'text', this.translate.instant('Goal'), '',
      this.data?.view?.title, this.translate.instant('Title')
    ).then(data => {
      if (data) this.data.view.title = data;
    });
  }

  //----------------------------------------------------------------------------
  // Set Category
  //----------------------------------------------------------------------------

  async setCategory() {
    this.firebase.setEvent('assistant_category');
    
    // Modal
    await this.ionic.openPage(SearchPage, 'categories', 'category').then(json => {
      if (json) {
        this.data.view.categoryTitle = json.title;
      }
    });
  }

  //----------------------------------------------------------------------------
  // Set Activity
  //----------------------------------------------------------------------------

  async setActivity() {
    this.firebase.setEvent('assistant_activity');

    // Modal
    await this.ionic.openPage(SearchPage, 'activities', 'activity').then(json => {
      if (json) {
        this.data.view.activity = json.id;
        this.data.view.activityTitle = json.title;
      }
    });
  }

  //----------------------------------------------------------------------------
  // Set Place
  //----------------------------------------------------------------------------

  async setPlace() {
    this.firebase.setEvent('assistant_place');

    // Modal
    await this.ionic.openPage(SearchPage, 'places', 'place').then(json => {
      if (json) {
        this.data.view.placeTitle = json.title;
      }
    });
  }

  //----------------------------------------------------------------------------
  // Set Days
  //----------------------------------------------------------------------------

  async setDays() {
    this.firebase.setEvent('assistant_days');
    
    // Prompt
    await this.ionic.showPrompt(
      'dates', this.translate.instant('Planned'),
      this.translate.instant('Planned'), this.data?.view?.startDate + ' ' + this.data?.view?.endDate
    ).then(data => {
      if (data) {
        const dates = data.split(' ');
        const oneDay = 24 * 60 * 60 * 1000;
        const firstDate = new Date(dates[0]).valueOf();
        const secondDate = new Date(dates[1]).valueOf();
        const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
        this.data.view.startDate = dates[0];
        this.data.view.endDate = dates[1];
        this.data.view.days = diffDays + 1;
      }
    });
  }

  //----------------------------------------------------------------------------
  // Close View
  //----------------------------------------------------------------------------

  async closePage() {
    const modal = await this.modalAssistant.getTop();
    if (modal) {
      modal.setCurrentBreakpoint(0);
    } else {
      await this.modalAssistant.dismiss();
    }
    this.events.refreshPages();
  }
}
