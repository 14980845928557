<ion-header mode="ios">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="closePage()">
        <ion-icon [src]="'/assets/icons/dark/x.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <img src="assets/logos/icon-white.svg">
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <div class="title">{{ 'PremiumTitle' | translate }}</div>
  <div class="subtitle">{{ 'PremiumSubtitle' | translate }}</div>
  <div class="features">
    <div class="feature">
      <img src="assets/svg/premium-goals.svg">
      <div class="label">{{ 'Goals' | translate }}</div>
    </div>
    <div class="feature">
      <img src="assets/svg/premium-places.svg">
      <div class="label">{{ 'Places' | translate }}</div>
    </div>
    <div class="feature">
      <img src="assets/svg/premium-friends.svg">
      <div class="label">{{ 'Friends' | translate }}</div>
    </div>
    <div class="feature">
      <img src="assets/svg/premium-images.svg">
      <div class="label">{{ 'Images' | translate }}</div>
    </div>
    <div class="feature soon">
      <img src="assets/svg/premium-passport.svg">
      <div class="label">{{ 'Passport' | translate }}</div>
    </div>
    <div class="feature soon">
      <img src="assets/svg/premium-journals.svg">
      <div class="label">{{ 'Journals' | translate }}</div>
    </div>
  </div>
  <div class="extras">
    {{ 'PremiumExtras' | translate }}
  </div>
  <!--
  <div class="awards">
    <img src="assets/svg/premium-awards.svg">
  </div>
  -->

  <ion-modal class="modal" [isOpen]="packages" [initialBreakpoint]="1" [breakpoints]="[0,1]" backdropDismiss="true">
    <ng-template>
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button ion-button (click)="closeModal()">
              <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-title>{{ 'Packages' | translate }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>

        <!-- Premium -->
        
        <div class="package">
          <div class="sale" *ngIf="data?.packages?.premium?.discount">
            {{ data?.packages?.premium?.discount }}
          </div>
          <div class="title">
            <b>{{ 'Premium' | translate }}</b>
            <!--{{ 'PremiumIndividual' | translate }}-->
          </div>
          <div class="includes">
             <div class="include">
                <ion-icon [src]="'/assets/icons/grey/check.svg'"></ion-icon>
                {{ 'PremiumFeatureGoals' | translate }}
             </div>
             <div class="include">
                <ion-icon [src]="'/assets/icons/grey/check.svg'"></ion-icon>
                {{ 'PremiumFeaturePlaces' | translate }}
             </div>
             <div class="include">
                <ion-icon [src]="'/assets/icons/grey/check.svg'"></ion-icon>
                {{ 'PremiumFeatureMedia' | translate }}
             </div>
             <div class="include">
                <ion-icon [src]="'/assets/icons/grey/check.svg'"></ion-icon>
                {{ 'PremiumFeatureInvites' | translate }}
             </div>
             <div class="include">
                <ion-icon [src]="'/assets/icons/grey/check.svg'"></ion-icon>
                {{ 'PremiumExtras' | translate }}
             </div>
          </div>
          <div class="button" [class.spinner]="spinner" [class.purchased]="data.packages.premium.purchased" [class.locked]="data.packages.premium.locked" (click)="purchasePackage('premium')">
            <span *ngIf="!data.packages.premium.purchased && !data.packages.premium.locked">{{ 'PremiumBuy' | translate: { price: data.packages.premium.price } }}</span>
            <span *ngIf="data.packages.premium.purchased">{{ 'PremiumOwned' | translate }}</span>
            <span *ngIf="data.packages.premium.locked && !data.packages.premium.purchased">{{ 'PremiumLocked' | translate }}</span>
          </div>
        </div>

        <!-- Goals -->
        
        <div class="package">
          <div class="sale" *ngIf="data?.packages?.goals?.discount">
            {{ data?.packages?.premium?.discount }}
          </div>
          <div class="title">
            <b>{{ 'Goals' | translate }}</b>
            <!--{{ 'PremiumIndividual' | translate }}-->
          </div>
          <div class="includes">
             <div class="include">
                <ion-icon [src]="'/assets/icons/grey/check.svg'"></ion-icon>
                {{ 'PremiumFeatureGoals' | translate }}
             </div>
             <div class="exclude">
                <ion-icon [src]="'/assets/icons/red/x.svg'"></ion-icon>
                {{ 'PremiumFeaturePlaces' | translate }}
             </div>
             <div class="include">
                <ion-icon [src]="'/assets/icons/grey/check.svg'"></ion-icon>
                {{ 'PremiumFeatureMedia' | translate }}
             </div>
             <div class="include">
                <ion-icon [src]="'/assets/icons/grey/check.svg'"></ion-icon>
                {{ 'PremiumFeatureInvites' | translate }}
             </div>
             <div class="include">
                <ion-icon [src]="'/assets/icons/grey/check.svg'"></ion-icon>
                {{ 'PremiumExtras' | translate }}
             </div>
          </div>
          <div class="button" [class.spinner]="spinner" [class.purchased]="data.packages.goals.purchased" [class.locked]="data.packages.goals.locked" (click)="purchasePackage('goals')">
            <span *ngIf="!data.packages.goals.purchased && !data.packages.goals.locked">{{ 'PremiumBuy' | translate: { price: data.packages.goals.price } }}</span>
            <span *ngIf="data.packages.goals.purchased">{{ 'PremiumOwned' | translate }}</span>
            <span *ngIf="data.packages.goals.locked && !data.packages.goals.purchased">{{ 'PremiumLocked' | translate }}</span>
          </div>
        </div>

        <!-- Places -->
        
        <div class="package">
          <div class="sale" *ngIf="data?.packages?.places?.discount">
            {{ data?.packages?.premium?.discount }}
          </div>
          <div class="title">
            <b>{{ 'Places' | translate }}</b>
            <!--{{ 'PremiumIndividual' | translate }}-->
          </div>
          <div class="includes">
             <div class="exclude">
                <ion-icon [src]="'/assets/icons/red/x.svg'"></ion-icon>
                {{ 'PremiumFeatureGoals' | translate }}
             </div>
             <div class="include">
                <ion-icon [src]="'/assets/icons/grey/check.svg'"></ion-icon>
                {{ 'PremiumFeaturePlaces' | translate }}
             </div>
             <div class="include">
                <ion-icon [src]="'/assets/icons/grey/check.svg'"></ion-icon>
                {{ 'PremiumFeatureMedia' | translate }}
             </div>
             <div class="include">
                <ion-icon [src]="'/assets/icons/grey/check.svg'"></ion-icon>
                {{ 'PremiumFeatureInvites' | translate }}
             </div>
             <div class="include">
                <ion-icon [src]="'/assets/icons/grey/check.svg'"></ion-icon>
                {{ 'PremiumExtras' | translate }}
             </div>
          </div>
          <div class="button" [class.spinner]="spinner" [class.purchased]="data.packages.places.purchased" [class.locked]="data.packages.places.locked" (click)="purchasePackage('places')">
            <span *ngIf="!data.packages.places.purchased && !data.packages.places.locked">{{ 'PremiumBuy' | translate: { price: data.packages.places.price } }}</span>
            <span *ngIf="data.packages.places.purchased">{{ 'PremiumOwned' | translate }}</span>
            <span *ngIf="data.packages.places.locked && !data.packages.places.purchased">{{ 'PremiumLocked' | translate }}</span>
          </div>
        </div>

      </ion-content>
    </ng-template>
  </ion-modal>
  
</ion-content>
  
<ion-footer slot="fixed">
  <div class="sale" *ngIf="data && !data.packages.premium.purchased">
    {{ data?.packages?.premium?.discount }}
  </div>

  <button ion-button class="buy" [class.purchased]="data && data.packages.premium.purchased" [class.spinner]="spinner" (click)="purchasePackage('premium')">
    <div class="purchase" *ngIf="data && !data.packages.premium.purchased">
      <div class="title" *ngIf="data">{{ 'PremiumBuy' | translate: { price: data.packages.premium.price } }} </div>
      <div class="subtitle">{{ 'PremiumOnce' | translate }}</div>
    </div>
    <div class="purchased" *ngIf="data && data.packages.premium.purchased">
      <div class="title">{{ 'PremiumOwned' | translate }}</div>
      <div class="subtitle">{{ 'PremiumThanks' | translate }}</div>
    </div>
  </button>

  <button ion-button class="other" (click)="showPackages($event)">
    <div class="title">{{ 'PremiumPackages' | translate }}</div>
  </button>

  <ul class="links">
    <!--<li><a (click)="showFeatures()">{{ 'PremiumTry' | translate }}</a></li>-->
    <li><a (click)="openReviews()">{{ 'PremiumReviews' | translate }}</a></li>
    <li><a (click)="restorePackage()">{{ 'PremiumRestore' | translate }}</a></li>
  </ul>
  
</ion-footer>