<ion-item-sliding #slidingItem *ngIf="data != 'unsplash' && data?.type != 'listheader'">
  
  <!--------------------------------------------------------------------------->
  <!-- Complete -->
  <!--------------------------------------------------------------------------->

  <ion-item-options side="start" *ngIf="options">
    <ion-item-option (click)="completeItem($event);slidingItem.close()" color="success">
      <ion-icon slot="icon-only" src="/assets/icons/color/check.svg"></ion-icon>
    </ion-item-option>
  </ion-item-options>
  
  <!--------------------------------------------------------------------------->
  <!-- Loading -->
  <!--------------------------------------------------------------------------->

  <ion-item button class="loading" disabled detail="false" *ngIf="(removing$ | async) == data?.id">
    <ion-spinner name="circular"></ion-spinner>
  </ion-item>
  
  <!--------------------------------------------------------------------------->
  <!-- Content -->
  <!--------------------------------------------------------------------------->

  <ion-item button mode="ios" class="{{type}} type-{{data?.type}} {{data?.display}} {{class}}" [class.images]="data?.images && data?.images?.image1"
    [class.tags]="data?.tags" [class.invites]="data?.invite != '' && data?.actions == 'invites'" [class.reorder]="reorder"
    [class.toggle]="toggle" [class.select]="select" [disabled]="disabled"
    [class.inactive]="!sublabel && type == 'manage'"
    [class.invited]="data?.status == 'received'"
    (click)="openItem()" detail="false" *ngIf="(removing$ | async) != data?.id">

    <!-- Icon -->
    
    <ion-spinner *ngIf="spinner && type == 'single'"></ion-spinner>
    <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + icon" *ngIf="(loading$ | async) != data?.id && icon && !icon.includes('.svg') && type == 'single'"></ion-icon>
    <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/' + icon" *ngIf="(loading$ | async) != data?.id && icon && icon.includes('.svg') && type == 'single'"></ion-icon>
    <ion-icon [src]="'/assets/icons/color/' + icon" *ngIf="(loading$ | async) != data?.id && icon && icon.includes('.svg') && type == 'manage'"></ion-icon>

    <!-- Task -->

    <ion-thumbnail slot="start" class="task" *ngIf="data?.type == 'task' && data?.display != 'social'" slot="start">
      <div class="check {{data?.status}}" (click)="completeItem($event)">
        <ion-icon src="/assets/icons/color/check.svg" class="task-check"></ion-icon>
      </div>
    </ion-thumbnail>

    <!-- Thumbnails -->

    <ion-thumbnail slot="start" [hidden]="data?.type == 'task' && data?.display != 'social'" (click)="openProfile($event)" *ngIf="data?.status != 'received'">
      <!-- Images -->
      <cached-image [src]="data?.avatar" [alt]="data?.title" [cache]="cache" width="46" height="46" *ngIf="data?.avatar && data?.display == 'social'"></cached-image>
      <cached-image [src]="data?.image" [alt]="data?.title" [cache]="cache" width="46" height="46" *ngIf="data?.image && data?.display != 'social'"></cached-image>
      <!-- Icons -->
      <ion-icon src="/assets/icons/color/check.svg" class="green" *ngIf="data?.status == 'completed' && !data?.search && data?.type != 'place'"></ion-icon>
      <ion-icon src="/assets/icons/color/plus.svg" class="blue" *ngIf="data?.status == 'add' && data?.display == 'social'"></ion-icon>
      <!-- Avatar -->
      <img class="avatar" [src]="data?.avatar" [alt]="data?.name" width="20" height="20" *ngIf="data?.avatar && data?.display == 'invite'"/>
      <div class="avatar" width="20" height="20" *ngIf="!data?.avatar && data?.display == 'invite'"></div>
    </ion-thumbnail>

    <!-- Labels -->

    <ion-label *ngIf="select == ''">
      <div class="title {{data?.type}} {{data?.status}}" *ngIf="data?.title || label">
        <span *ngIf="date">{{ ionic.showDateTime(label) }}</span>
        <span *ngIf="!date">{{ label | translate }}</span>
        <span *ngIf="data?.title">{{ data?.title }}</span>
      </div>
      <div class="subtitle" *ngIf="data?.subtitle || sublabel">
        <span class="grey bold" *ngIf="data?.display == 'journal'">
          {{ ionic.showDay(data?.updated) }} • 
        </span>
        <span class="grey bold" *ngIf="data?.time">
          {{ data?.time }} • 
        </span>
        {{ data?.subtitle }}{{ sublabel | translate }}
      </div>
      <div class="story" *ngIf="data?.story">
        {{ data?.story }}
      </div>
    </ion-label>

    <ion-label slot="end" class="value" *ngIf="type == 'single' && value">
      {{ value }}
    </ion-label>

    <!-- Images -->

    <div slot="start" class="images-group" [class.twice]="data?.images?.image2" [class.thrice]="data?.images?.image3">
      <div class="placeholder" *ngIf="!data?.image && !data?.images?.image1 && capacitor.display"></div>
      <ion-img class="img1" [src]="data?.image" *ngIf="data?.image && !data?.images?.image1"></ion-img>
      <ion-img class="img1" [src]="data?.images?.image1" *ngIf="data?.images?.image1"></ion-img>
      <ion-img class="img2" [src]="data?.images?.image2" *ngIf="data?.images?.image2"></ion-img>
      <ion-img class="img3" [src]="data?.images?.image3" *ngIf="data?.images?.image3"></ion-img>
    </div>
    
    <!-- Place -->

    <ion-button class="action primary" [disabled]="(loading$ | async) == data?.id" [class.solid]="data?.status == 'added'" 
      (click)="data?.status == 'added' ? deletePlace($event) : addPlace($event)" *ngIf="data?.type == 'place' && data?.display == 'map'">
      <ion-icon src="/assets/icons/color/heart.svg" *ngIf="(loading$ | async) != data?.id && data?.status == 'added'"></ion-icon>
      <ion-icon src="/assets/icons/color/heart-primary.svg" *ngIf="(loading$ | async) != data?.id && data?.status != 'added'"></ion-icon>
      <ion-spinner name="circular" *ngIf="(loading$ | async) == data?.id"></ion-spinner>
    </ion-button>
    
    <ion-button class="action success" [disabled]="(loading$ | async) == data?.id" [class.solid]="data?.status == 'completed'" 
      (click)="data?.status == 'completed' ? deletePlace($event) : completePlace($event)" *ngIf="data?.type == 'place' && data?.display == 'map'">
      <ion-icon src="/assets/icons/color/flag-01.svg" *ngIf="(loading$ | async) != data?.id && data?.status == 'completed'"></ion-icon>
      <ion-icon src="/assets/icons/color/flag-01-success.svg" *ngIf="(loading$ | async) != data?.id && data?.status != 'completed'"></ion-icon>
      <ion-spinner name="circular" *ngIf="(loading$ | async) == data?.id"></ion-spinner>
    </ion-button>

    <!-- Invite -->

    <ion-button class="action success solid" mode="ios" [disabled]="(loading$ | async) == data?.id"
      (click)="acceptInvite($event)" *ngIf="data?.token && data?.status == 'received'">
      <ion-icon src="/assets/icons/color/check.svg" *ngIf="(loading$ | async) != data?.id"></ion-icon>
      <ion-spinner name="circular" *ngIf="(loading$ | async) == data?.id"></ion-spinner>
    </ion-button>

    <ion-button class="action danger solid" mode="ios" [disabled]="(loading$ | async) == data?.id"
      (click)="declineInvite($event)" *ngIf="data?.token && data?.status == 'received'">
      <ion-icon src="/assets/icons/color/x.svg" *ngIf="(loading$ | async) != data?.id"></ion-icon>
      <ion-spinner name="circular" *ngIf="(loading$ | async) == data?.id"></ion-spinner>
    </ion-button>

    <ion-button class="action" [disabled]="(loading$ | async) == data?.id" 
      (click)="friends.shareInvite(data?.share, data?.title, data?.name, $event)" *ngIf="data?.status == 'invited' && !data?.maximum">
      <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/link-03.svg'" *ngIf="(loading$ | async) != data?.id"></ion-icon>
      <ion-spinner name="circular" *ngIf="(loading$ | async) == data?.id"></ion-spinner>
    </ion-button>
    
    <ion-button class="action danger" [disabled]="(loading$ | async) == data?.id" 
      (click)="deleteInvite($event)" *ngIf="data?.status == 'accepted' || data?.status == 'invited'">
      <ion-icon src="/assets/icons/color/trash-01.svg" *ngIf="(loading$ | async) != data?.id"></ion-icon>
      <ion-spinner name="circular" *ngIf="(loading$ | async) == data?.id"></ion-spinner>
    </ion-button>

    <!-- Friends -->

    <ion-button class="action danger" [disabled]="(loading$ | async) == data?.id"
      (click)="likeFeed($event)" *ngIf="data?.display == 'social'">
      <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/thumbs-up.svg'" *ngIf="(loading$ | async) != data?.id && !data?.liked"></ion-icon>
      <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/thumbs-up-solid.svg'" *ngIf="(loading$ | async) != data?.id && data?.liked"></ion-icon>
      <ion-spinner name="circular" *ngIf="(loading$ | async) == data?.id"></ion-spinner>
    </ion-button>

    <ion-button class="action success solid" mode="ios" [disabled]="(loading$ | async) == data?.id" 
      (click)="approveFriend($event)" *ngIf="data?.type == 'user' && data?.status == 'received' && !data?.token">
      <ion-icon src="/assets/icons/color/check.svg" *ngIf="(loading$ | async) != data?.id"></ion-icon>
      <ion-spinner name="circular" *ngIf="(loading$ | async) == data?.id"></ion-spinner>
    </ion-button>

    <ion-button class="action danger solid" mode="ios" [disabled]="(loading$ | async) == data?.id"
      (click)="denyFriend($event)" *ngIf="data?.type == 'user' && data?.status == 'received' && !data?.token">
      <ion-icon src="/assets/icons/color/x.svg" *ngIf="(loading$ | async) != data?.id"></ion-icon>
      <ion-spinner name="circular" *ngIf="(loading$ | async) == data?.id"></ion-spinner>
    </ion-button>

    <!-- Search -->

    <ion-button class="action primary" mode="ios" [disabled]="(loading$ | async) == data?.id"
      (click)="openManage(data?.type, $event)" *ngIf="data?.display == 'ideas' && data?.search">
      <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/plus.svg'" *ngIf="(loading$ | async) != data?.id"></ion-icon>
      <ion-spinner name="circular" *ngIf="(loading$ | async) == data?.id"></ion-spinner>
    </ion-button>

    <ion-button class="action primary" mode="ios" [disabled]="(loading$ | async) == data?.id"
      (click)="openManage('goal', $event)" *ngIf="data?.display == 'places' && data?.search">
      <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/plus.svg'" *ngIf="(loading$ | async) != data?.id"></ion-icon>
      <ion-spinner name="circular" *ngIf="(loading$ | async) == data?.id"></ion-spinner>
    </ion-button>

    <ion-button class="action warning" mode="ios" [disabled]="(loading$ | async) == data?.id"
      (click)="editItem($event)" *ngIf="data?.display == 'friends' && data?.search">
      <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/users-plus.svg'" *ngIf="(loading$ | async) != data?.id"></ion-icon>
      <ion-spinner name="circular" *ngIf="(loading$ | async) == data?.id"></ion-spinner>
    </ion-button>

    <!-- Select -->
  
    <ion-select mode="ios" [label]="label | translate" [(ngModel)]="data" [value]="data" [okText]="'Select' | translate" [cancelText]="'Dismiss' | translate" (ionChange)="changeSelect.emit(data)" *ngIf="select == 'language'">
      <ion-select-option value="en">English</ion-select-option>
      <ion-select-option value="nl">Nederlands</ion-select-option>
      <ion-select-option value="es">Español</ion-select-option>
      <ion-select-option value="pt">Português</ion-select-option>
      <ion-select-option value="fr">Français</ion-select-option>
      <ion-select-option value="it">Italiano</ion-select-option>
      <ion-select-option value="de">Deutsch</ion-select-option>
    </ion-select>
    
    <ion-select mode="ios" [label]="label | translate" [(ngModel)]="data" [value]="data" [okText]="'Select' | translate" [cancelText]="'Dismiss' | translate" (ionChange)="changeSelect.emit(data)" *ngIf="select == 'appearance'">
      <ion-select-option value="system">{{ 'System' | translate }}</ion-select-option>
      <ion-select-option value="light">{{ 'Light' | translate }}</ion-select-option>
      <ion-select-option value="dark">{{ 'Dark' | translate }}</ion-select-option>
    </ion-select>

    <!-- Toggles -->
  
    <ion-toggle aria-label="Toggle" slot="end" mode="ios" [(ngModel)]="data" (click)="changeToggle.emit(!data)" *ngIf="toggle"></ion-toggle>
    <ion-toggle aria-label="Toggle" slot="end" mode="ios" [(ngModel)]="data.invite" (ngModelChange)="toggleInvite()" *ngIf="data?.actions == 'invites'"></ion-toggle>

    <!-- Order -->

    <ion-reorder *ngIf="data?.status != 'place' && !lock && reorder && options && (sort == 'manual:asc' || sort == 'manual:desc' || reorder == 'trip')">
      <ion-icon class="reorder" src="/assets/icons/color/equal.svg"></ion-icon>
    </ion-reorder>

    <!-- Premium -->
    <div class="premium" *ngIf="premium && !capacitor.premium">
      {{ 'Premium' | translate }}
    </div>

    <!-- More -->

    <ion-icon src="/assets/icons/color/dots-vertical.svg" class="more" *ngIf="options || data?.edit" (click)="editItem($event)" slot="end"></ion-icon>
    <ion-icon src="/assets/icons/color/trash-01.svg" class="trash" (click)="events.publishEvent('manage', value);$event.stopPropagation();" *ngIf="type == 'manage' && data && value"></ion-icon>
    <ion-icon src="/assets/icons/color/chevron-right.svg" class="chevron" slot="end" *ngIf="!toggle || type == 'single' || type == 'manage'" [hidden]="type == 'manage' && data && value"></ion-icon>

    <!-- Tags
  
    <div class="tags-group" slot="start">
      <button class="cta small background tertiary" mode="ios" *ngIf="data?.reservation">
        <ion-icon [src]="'/assets/icons/color/hash-01.svg'"></ion-icon>
        {{ data?.reservation }}
      </button>
      <button class="cta small background tertiary" mode="ios" *ngIf="data?.time">
        <ion-icon [src]="'/assets/icons/color/clock.svg'"></ion-icon>
        {{ data?.time }}
      </button>
      <button class="cta small background tertiary" mode="ios" *ngIf="data?.budget">
        <ion-icon [src]="'/assets/icons/color/wallet-02.svg'"></ion-icon>
        {{ data?.budget }}
      </button>
      <button class="cta small background tertiary circle" mode="ios" *ngIf="data?.tickets">
        <ion-icon [src]="'/assets/icons/color/ticket-01.svg'"></ion-icon>
      </button>
      <button class="cta small background tertiary circle" mode="ios" *ngIf="data?.reminder">
        <ion-icon [src]="'/assets/icons/color/bell-ringing-01.svg'"></ion-icon>
      </button>
    </div> -->
  
    <!-- Invite -->
  
    <div class="invite-group" slot="start">
      <button class="cta medium background" mode="ios" *ngIf="(loading$ | async) == data?.id">
        <ion-spinner name="circular"></ion-spinner>
      </button>
      <button class="cta medium background" mode="ios" (click)="openPremium()" *ngIf="(loading$ | async) != data?.id && data?.maximum">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/lock-01.svg'"></ion-icon>
        {{ 'InviteUpgrade' | translate }}
      </button>
      <button class="cta medium background" mode="ios" (click)="shareInvite($event)" *ngIf="(loading$ | async) != data?.id && !data?.maximum">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/link-03.svg'"></ion-icon>
        {{ 'Share' | translate }}
      </button>
      <button class="cta medium background" mode="ios" (click)="userInvite($event)" *ngIf="(loading$ | async) != data?.id && !data?.maximum">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/search-refraction.svg'"></ion-icon>
        {{ 'Search' | translate }}
      </button>
    </div>
  </ion-item>
  
  <!--------------------------------------------------------------------------->
  <!-- Delete -->
  <!--------------------------------------------------------------------------->

  <ion-item-options side="end" *ngIf="options">
    <ion-item-option (click)="deleteItem($event);slidingItem.close()" color="danger">
      <ion-icon src="/assets/icons/color/trash-03.svg" slot="icon-only"></ion-icon>
    </ion-item-option>
  </ion-item-options>

</ion-item-sliding>

<!-- Header -->

<app-header title="{{ ionic.showDay(data?.planned) }}" add="true" type="goal" class="small" [data]="data" *ngIf="data?.type == 'listheader'"></app-header>

<!-- Image -->

<div class="unsplash" (click)="openItem()" [style.background-image]="'url(' + data?.image + ')'" *ngIf="data?.type == 'unsplash'">
  <div class="copyright">{{ data?.title }}</div>
</div>