import { Injectable } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SearchPage } from '../../pages';
import {
  CapacitorService,
  FirebaseService,
  IonicService,
  EventsService,
  HttpService,
  CacheService
} from '..';

@Injectable({
  providedIn: 'root'
})
export class GoalsService {

  manage: any;

  //----------------------------------------------------------------------------
  // Constructor
  //----------------------------------------------------------------------------

  constructor(
    public capacitor: CapacitorService,
    public translate: TranslateService,
    public firebase: FirebaseService,
    public modalGoals: ModalController,
    public events: EventsService,
    public ionic: IonicService,
    public cache: CacheService,
    public platform: Platform,
    public http: HttpService,
    public router: Router,
  ) {}

  //----------------------------------------------------------------------------
  // Set Status
  //----------------------------------------------------------------------------

  async setStatus(id: string, status: string) {

    // Request
    this.http.putRequest('/view/' + id, {
      status: status == 'add' ? 'completed' : 'add',
      completed: status == 'add' ? new Date().toISOString() : ''
    }).then(data => {
      this.events.refreshPages();
      return data;
    });
  }

  //----------------------------------------------------------------------------
  // Set Name
  //----------------------------------------------------------------------------

  async setName(id: string, title: string) {
    this.firebase.setEvent('view_name');

    // Prompt
    const name = await this.ionic.showPrompt(
      'text', this.translate.instant('Rename'), '',
      title, this.translate.instant('Title')
    );
    if (!name) return;

    // Request
    this.http.putRequest('/view/' + id, {
      title: name
    }).then(data => {
      this.events.refreshPages();
      return data;
    });
  }

  //----------------------------------------------------------------------------
  // Set Parent
  //----------------------------------------------------------------------------

  async setParent(id: string, bucket: string, trip: string, goal: string) {

    // Modal
    await this.ionic.openPage(SearchPage, 'goals').then(json => {
      if (json['id']) {

        let moved;
        if(bucket) moved = bucket;
        if(trip) moved = trip;
        if(goal) moved = goal;

        // Request
        this.http.putRequest('/view/' + id, {
          bucket: json['type'] == 'bucket' ? json['id'] : '',
          trip: json['type'] == 'trip' ? json['id'] : '',
          goal: json['type'] == 'goal' ? json['id'] : '',
          moved: moved

        }).then(data => {
          this.events.refreshPages();
          return data;
        });
      }
    });
  }

  //----------------------------------------------------------------------------
  // Delete Item
  //----------------------------------------------------------------------------

  async deleteItem(id: string) {

    // Confirm
    const remove = await this.ionic.showConfirm(
      this.translate.instant('Delete'),
      this.translate.instant('DeleteConfirm')
    );
    if (!remove) return;

    // Request
    this.http.isRemoving(id);
    await this.http.deleteRequest('/view/' + id).then(async data => {
      if (data) {
        this.cache.removeCache(id);
        this.events.refreshPages();
      }
    });
  }
}
