<ion-header mode="ios" *ngIf="type || token">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button text="" [icon]="'/assets/icons/' + capacitor.scheme + '/' + 'arrow-left.svg'" defaultHref="/" *ngIf="token"></ion-back-button>
      <ion-button class="close" (click)="closePage()" *ngIf="!token">
        <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/x.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="type == 'login' || type == 'magic'">{{ 'Login' | translate }}</ion-title>
    <ion-title *ngIf="type == 'verify'">{{ 'Verify' | translate }}</ion-title>
    <div class="ibucket" *ngIf="token"></div>
  </ion-toolbar>
</ion-header>

<ion-content scroll-y="false" [class.overflow]="!type">

  <!-- Splash -->
  
  <div class="splash" *ngIf="!type && !token">
    <div class="view">
      <div class="title">
        Dream it.<br>
        Plan it.<br>
        <span>Do it.</span>
      </div>
    </div>
  </div>

  <!-- Login -->

  <ion-grid class="medium" *ngIf="type && !token">
    <form>
      
      <!-- Login -->

      <div *ngIf="type == 'login'">
        <div class="subtitle">{{ 'LoginWelcome' | translate }}</div>
        <ion-input type="email" mode="ios" class="input" [(ngModel)]="email" name="email" placeholder="{{ 'LoginEmail' | translate }}"></ion-input>
        <button ion-button type="submit" class="button" (click)="loginEmail()" [disabled]="!email || spinner">
          {{ 'Login' | translate }}
          <ion-spinner class="white" *ngIf="spinner"></ion-spinner>
        </button>
      </div>

      <!-- Magiclink -->

      <div *ngIf="type == 'magic'">
        <div class="subtitle">{{ 'LoginMagic' | translate }}</div>
        <button ion-button (click)="closePage()" class="button first">
          {{ 'Close' | translate }}
        </button>
        <button ion-button class="button outline grey" [disabled]="spinner" (click)="loginEmail()">
          {{ 'LoginResend' | translate }}
          <ion-spinner class="white" *ngIf="spinner"></ion-spinner>
        </button>
      </div>

      <!-- Verify -->

      <div *ngIf="type == 'verify'">
        <div class="subtitle">{{ 'VerifyInfo' | translate }}</div>
        <ion-input autocapitalize="on" type="text" mode="ios" class="input" [(ngModel)]="name" name="name" placeholder="{{ 'LoginName' | translate }}"></ion-input>
        <ion-input type="email" class="input" mode="ios" [(ngModel)]="email" name="email" placeholder="{{ 'LoginEmail' | translate }}"></ion-input>
        <button ion-button type="submit" class="button" (click)="verifyAccount()" [disabled]="!name || !email || spinner">
          {{ 'LoginUpdate' | translate }}
          <ion-spinner class="white" *ngIf="spinner"></ion-spinner>
        </button>
      </div>

      <!-- Social -->

      <div class="social" *ngIf="type != 'magic'">
        <button ion-button (click)="loginSocial('apple')" class="button apple" [disabled]="spinner" *ngIf="capacitor.platform != 'android'">
          <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/social-apple.svg'"></ion-icon> {{ 'LoginApple' | translate }}
          <ion-spinner class="white" *ngIf="spinner"></ion-spinner>
        </button>
        <button ion-button (click)="loginSocial('google')" class="button google" [disabled]="spinner">
          <ion-icon [src]="'/assets/icons/' + capacitor.scheme + '/social-google.svg'"></ion-icon> {{ 'LoginGoogle' | translate }}
          <ion-spinner class="white" *ngIf="spinner"></ion-spinner>
        </button>
      </div>

    </form>
  </ion-grid>

  <!-- Token -->

  <ion-grid *ngIf="!type && token">
    <ion-list>
      <div class="accept">
        <img src="/assets/svg/login-visual.svg" width="200" height="200" class="visual" />
        <h1>Welcome back!</h1>
        <div class="text">
          Do you want to automatically login on our website or mobile app?
        </div>
        <div class="buttons">
          <a ion-button href="ibucket://login/{{token}}" class="cta big primary" *ngIf="web">
            Open app
          </a>
          <a ion-button class="cta big primary" [class.secondary]="web" (click)="loginAccept()" *ngIf="!capacitor.native">
            Login on web
          </a>
        </div>
      </div>
    </ion-list>
    <ion-list *ngIf="!capacitor.native">
      <div class="accept">
        <div class="buttons" *ngIf="web && token">
          <div class="text"><strong>Or download our app:</strong></div>
          <a href="https://itunes.apple.com/app/id864052715" title="App Store" target="_blank" rel="noopener" *ngIf="platform.is('ios')">
            <img src="/assets/svg/download-apple.svg" width="149" height="44" alt="Download App Store">
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.kaimallie.ibucket" title="Google Play" target="_blank" rel="noopener" *ngIf="platform.is('android')">
            <img src="/assets/svg/download-google.svg" width="149" height="44" alt="Download Google Play">
          </a>
        </div>
        <div class="buttons" *ngIf="!web && token">
          <h2>Login in our app? Scan the QR:</h2>
          <div class="qrcode">
            <img src="https://api.qrserver.com/v1/create-qr-code/?size=400x400&color=181F36&data=ibucket://login/{{token}}" class="qr" width="190" height="190" alt="Scan QR">
            <span>Open</span>
          </div>
          <div class="qrcode">
            <img src="https://api.qrserver.com/v1/create-qr-code/?size=400x400&color=181F36&data=https://web.ibucket.app/login/{{token}}" class="qr" width="190" height="190" alt="Scan QR">
            <span>Download</span>
          </div>
        </div>
      </div>
    </ion-list>
  </ion-grid>

</ion-content>
  
<ion-footer slot="fixed" *ngIf="!type && !token">
  <button ion-button type="submit" class="button create" [class.spinner]="spinner" (click)="createAccount()">{{ 'LoginCreate' | translate }}</button>
  <button ion-button type="submit" class="button login" [disabled]="spinner" (click)="openLogin()">{{ 'Login' | translate }}</button>
</ion-footer>